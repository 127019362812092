import React from 'react';
import NavBar from '../Univeral Components/navbar';
import Footer from '../Univeral Components/footer';
import Routes from '../routes/routes';
import ScrollToTop from './ScrollToTop';
import './Navbar.css';
import { Helmet } from 'react-helmet';

const App = () => (
  <div>
    <Helmet>
      <title>XHighlight</title>
    </Helmet>
    <NavBar />
    <ScrollToTop />
    <div className='content'>
      <Routes />
    </div>
    <Footer />
  </div>
);

export default App;
