import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import Container from "../../Univeral Components/Container";
import styled from "styled-components";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import TypeForm from "./Typeform"

const getColor = (props) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isDragActive) {
    return "black";
  }
  return "#eeeeee";
};

const getColorBackground = (props) => {
  // if (props.isDragAccept) {
  //   return "green";
  // }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isDragActive) {
    return "#999999";
  }
  return "#fafafa";
};

const DropArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: ${(props) => getColorBackground(props)};
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;
// Original Backgournd COlor //#fafafa;



function DropElement() {
  const [myFiles, setMyFiles] = useState([]);
  const [allRejectedFiles, setRejectedFiles] = useState([]);

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    setRejectedFiles({ ...allRejectedFiles, ...rejectedFiles });
    console.log(allRejectedFiles.length + " was rejected");
    setMyFiles([...myFiles, ...acceptedFiles]);
    console.log(acceptedFiles.length + " was accepted");
  });
  const maxSize = 1500000000000000; //1.5 gigabytes

  const {
    isDragActive,
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
    acceptedFiles,
    rejectedFiles,
  } = useDropzone({
    onDrop,
    accept: "video/*",
    maxSize,
    multiple: true,
  });

  const isFileTooLarge =
    rejectedFiles &&
    rejectedFiles.length > 0 &&
    rejectedFiles[0].size > maxSize;

  const removeFile = (file) => () => {
    const newFiles = [...myFiles];
    console.log(file.path + " was removed");
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
  };

  const removeAll = () => {
    console.log("Uploads Cleared");
    setMyFiles([]);
  };

  const files = myFiles.map((file, key) => {
    let file_size = "";
    let num = 0;
    if (Math.round(file.size * 0.000000001 * 100) / 100 > 1) {
      num = Math.round(file.size * 0.000000001 * 100) / 100;
      file_size = num.toString() + "GB";
    } else if (Math.round(file.size * 0.000000001 * 1000 > 1)) {
      num = Math.round(file.size * 0.000000001 * 1000);
      file_size = num.toString() + "MB";
    } else {
      num = Math.round(file.size * 0.000001 * 1000);
      file_size = num.toString() + "KB";
    }
    return (
      <li key={file.path} style={{ height: "45px" }}>
        {file.path} - {file_size}
        <button
          onClick={removeFile(file)}
          style={{
            float: "right",
            backgroundColor: "white",
            borderRadius: "30px",
          }}
          className="btn btn-white"
          type="button"
        >
          Remove File
        </button>
      </li>
    );
  });

  const RFiles = Object.keys(allRejectedFiles).map((keyName, i) => {
    let math = maxSize * 0.000000001;
    let message = "There was an unknown error, please try a different file.";
    if (
      allRejectedFiles[keyName]["errors"][0]["message"].includes(
        "type must be video/*"
      )
    ) {
      message = "Only Video files are supported.";
    }
    if (
      allRejectedFiles[keyName]["errors"][0]["message"].includes(
        "File is larger than"
      )
    ) {
      message =
        "Your File is too large, Files must be less than " +
        math +
        " Gigabytes. Please break up your file and try again. ";
    }
    return (
      <li key={i}>
        {console.log(
          "Error Message" + allRejectedFiles[keyName]["errors"][0]["message"]
        )}
        file: {allRejectedFiles[keyName]["file"]["path"]} - {message}
      </li>
    );
  });

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            XHighlight is still in beta. Join Our Waiting List to learn when we launch to the public.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "500px" }}>
          <h4>Enter your email to get updates on our launch.</h4>
          {/* <p>
            Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
            dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
            consectetur ac, vestibulum at eros.
          </p> */}
          <p style={{ height: "200px" }}>
            <TypeForm />
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  const [modalShow, setModalShow] = React.useState(false);

  return (
    <form>
      <div style={{ backgroundColor: "red", padding: "25px" }}>
        <Container>
          <Container>
            <DropArea
              {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
            >
              <input {...getInputProps()} />
              {!isDragActive && "Click here or drop a file to upload!"}
              {isDragActive && !isDragReject && "Drop it like it's hot!"}
              {isDragReject && "File type not accepted, sorry!"}
              {isFileTooLarge && (
                <div className="text-danger mt-2">File is too large.</div>
              )}
              <div style={{ textAlign: "center" }}>
                <h2 style={{ color: "red" }}>
                  Drag 'n' Drop Here to Get Started
                </h2>
                <p>
                  Drag 'n' drop some mp4 files here, or click to select files.
                  You can select multiple files at once for analysis.
                </p>
              </div>
            </DropArea>
          </Container>
        </Container>
      </div>
      <div style={{ backgroundColor: "red" }}>
        <Container>
          <Container>
            <section>
              <aside>
                {files.length > 0 || (RFiles.length > 0 && <br></br>)}
                {/* {console.log(files)} */}
                {files.length > 0 && (
                  <div>
                    <br></br>
                    <h4 style={{ fontWeight: "bold", marginTop: "-20px" }}>
                      Accepted Files
                    </h4>
                    {files}
                  </div>
                )}
                {RFiles.length > 0 && (
                  <div>
                    <h4 style={{ fontWeight: "bold" }}>Rejected Files</h4>
                    <ul>{RFiles}</ul>
                  </div>
                )}
              </aside>
            </section>

            {files.length > 0 && (
              <button
                onClick={removeAll}
                style={{
                  backgroundColor: "white",
                  borderRadius: "30px",
                  padding: "20px",
                }}
                className="btn btn-white"
                type="button"
              >
                Remove All Files
              </button>
            )}
            <div style={{ textAlign: "center" }}>
              {files.length === 1 && (
                <div>
                  <button
                    className="col-11 btn-white"
                    type="button"
                    style={{
                      margin: "20px 0 20px 0",
                      border: "none",
                      color: "black",
                      padding: "20px",
                      textAlign: "center",
                      display: "inline-block",
                      borderRadius: "30px",
                    }}
                    className="btn-white col-11"
                    onClick={() => setModalShow(true)}
                  >

                    <span
                      style={{
                        fontSize: "30px",
                        margin: "5px 0 5px 0",
                        fontWeight: "bold",
                      }}
                    >
                      Upload and Analyze
                    </span>
                    <br />
                    <span style={{ padding: "0px", margin: "5px 0 5px 0" }}>
                      {files.length} file will be Uploaded and Analyzed
                    </span>
                  </button>
                  <MyVerticallyCenteredModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                  />
                </div>
              )}
              {files.length >= 2 && (
                <button
                  className="col-11 btn-white"
                  type="button"
                  onHide={() => setModalShow(false)}
                  style={{
                    margin: "20px 0 20px 0",
                    border: "none",
                    color: "black",
                    padding: "20px",
                    textAlign: "center",
                    display: "inline-block",
                    borderRadius: "30px",
                  }}
                  className="btn-white col-11"
                >
                  <span
                    style={{
                      fontSize: "30px",
                      margin: "5px 0 5px 0",
                      fontWeight: "bold",
                    }}
                  >
                    Upload and Analyze
                  </span>
                  <br />
                  <span style={{ padding: "0px", margin: "5px 0 5px 0" }}>
                    {files.length} files will be Uploaded and Analyzed
                  </span>
                </button>
              )}
            </div>
          </Container>
        </Container>
      </div>
    </form>
  );


}

export default DropElement;
