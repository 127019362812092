import React, { Component } from 'react';
import ReactLoading from 'react-loading';
import XHighlightRectrangle from '../../Assets/Page Assets/Xhighlight Logo Rectangle.png';

class Loading extends Component {
  render() {
    return (
      <div
        style={{
          textAlign: 'center',
          paddingTop: '100px',
          paddingBottom: '30px',
        }}
      >
        <img
          alt=''
          src={XHighlightRectrangle}
          width='50%'
          style={{ verticalAlign: 'bottom' }}
        />
        <div style={{ paddingLeft: '48%', paddingTop: '30px' }}>
          <ReactLoading type={'bars'} color={'red'} height={20} />
        </div>
        <div style={{ textAlign: 'center', paddingTop: '100px' }}>
          <h3>Your file(s) are being analyzed...</h3>
          <h5>
            <strong>Please wait...</strong>
          </h5>
        </div>
      </div>
    );
  }
}

export default Loading;
