import React from "react";

class NoMatch extends React.Component {
  render() {
    return (
      <div className="container text-center">
        <div className="container">
          <div style={{ marginTop: "auto", marginBottom: "auto", color: "grey" }}>
            <h1 style={{ marginTop: "1.5rem" }}>404 No Match</h1>
            <h1>Ugh Oh...</h1>
            <h1>
              No{" "}
              <img
                src={require("./../Assets/Page Assets/Xhighlight Logo Rectangle.png")}
                className="img-fluid"
                alt="XHighlight"
                max-width="62px"
                width="230px"
                // max-height="50px"
              />
              {"'s "}
              were found on this link.
            </h1>
            <h3>No reason to &#9733; this one I guess.</h3>
            <h4 style={{marginTop:"1.5em"}}>
              <a href="/">← to Home</a>
            </h4>
          </div>
        </div>
      </div>
    );
  }
}

export default NoMatch;
