import React from 'react';
import { Tabs, useTabState, Panel } from '@bumaga/tabs';
import './../../styles/creationProcess.css';
import ProcessTeam from '../../Assets/Custom Assets/Team Process - Equal.png';
import ProcessSolo from '../../Assets/Custom Assets/Single Process - Equal.png';
import XHighlightProcess from '../../Assets/Custom Assets/XHighlight Process - Equal.png';

const cn = (...args) => args.filter(Boolean).join(' ');

const Tab = ({ children }) => {
  const { isActive, onClick } = useTabState();

  return (
    <button
      className={cn('btn-tabs', isActive && 'active-tab')}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default () => (
  <div>
    {/* How are you making your video? */}
    <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
      <h2 className='headings'>How are you making your Highlight Video?</h2>
    </div>
    <Tabs>
      <div className='tabs centered '>
        <div className='tab-list pad-bottom'>
          <Tab>WITH A TEAM</Tab>

          <Tab>ALONE</Tab>
        </div>

        <div className='tab-progress' />

        <Panel>
          <img
            src={ProcessTeam}
            className='img-fluid'
            style={{ width: '90%' }}
            alt={
              'Team 6 step process for creating a video. First, the game begins with the video being recorded, (2.) multiple people are monitoring the game as it is going on. 3. The people watching the video mark the highlights as the game is going on. 4. The game ends with the video recorded and the highlights marked. 5. Staff go through and review the clips saved by staff members. 6. The Staff compiles the video into its final form. This is a total of 6 steps for 2 to 4 people and takes about 2 hours per person.'
            }
          />
        </Panel>

        <Panel>
          <img
            src={ProcessSolo}
            className='img-fluid'
            style={{ width: '90%' }}
            alt='The Single Person single step process without XHighlight. The Game begins with the video being recorded. 2. The Game ends. the producer watches the film 3 to 5 times. 3. The Highlights are identified. The Highlights are compared to one another and Finally, (6) the Clips are combined into a video. This is a total of 8 to 10 steps for 1 person and takes about 8 hours'
          />
        </Panel>
      </div>
    </Tabs>

    <ul className='tabsX' style={{ textAlign: 'center' }}>
      <li className='current' data-tab='tab-1'>
        WITH XHIGHLIGHT
      </li>
    </ul>
    <div className='centered'>
      <img
        src={XHighlightProcess}
        className='img-fluid'
        style={{ width: '90%' }}
        alt='The XHighlight Process is only 4 steps. Once the video is recorded, the video is uploaded where our AI (Artificial Intelligence) and Algorithm analyize the video. The user is given customizations and Finally the AI Generates the video.'
      />
    </div>
    {/* <Tabs state={[index, setIndex]}> */}
  </div>
);
