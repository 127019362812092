import React, { Component } from "react";

class Demo extends Component {
  render() {
    return (
      <div style={{ paddingBottom: "20px" }}>
        <div className="d-none d-xl-block">
          <h1
            style={{ margin: "30px 0 30px 0", paddingTop: "0px" }}
            className="headings"
          >
            XHighlight Demo
          </h1>
          <a href="/demo">
            <button
              style={{
                float: "right",
                border: "none",
                fontWeight: "bolder",
                color: "white",
                padding: "20px",
                textAlign: "center",
                // display: "inline-block",
                fontSize: "20px",
                marginTop: "-55px",
                // marginLeft: "auto",
                // marginRight: "auto",
                borderRadius: "15px",
              }}
              className="btn red"
            >
              View Results
            </button>
          </a>
          <h4>View Results of Our Demo File without Uploading.</h4>
        </div>
        <div className="d-xl-none">
          <h1
            style={{ margin: "30px 0 30px 0", paddingTop: "0px" }}
            className="headings"
          >
            XHighlight Demo
          </h1>
          <h4>View Results of Our Demo File without Uploading.</h4>
          <a href="/demo">
            <button
              style={{
                border: "none",
                fontWeight: "bolder",
                color: "white",
                padding: "20px",
                textAlign: "center",
                // display: "inline-block",
                fontSize: "20px",
                marginTop: "20px",
                // marginLeft: "auto",
                // marginRight: "auto",
                textAlign: "center",
                borderRadius: "15px",
              }}
              className="btn red"
            >
              View Results
            </button>
          </a>
        </div>
      </div>
    );
  }
}

export default Demo;
