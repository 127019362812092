import React, { Component } from "react";

class TagLine extends Component {
  render() {
    return (
      <div className="container">
        <h2 className="mt-4 headings" style={{ paddingBottom: "12px"}}>
          XHighlight: Fastest and Easiest way to make Sports Highlight 
          Videos
        </h2>
      </div>
    );
  }
}

export default TagLine;
