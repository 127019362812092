import React, { Component } from "react";
import { Accordion, Card, Button } from "react-bootstrap";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import { useContext } from "react";

function ContextAwareToggle({ children, eventKey, callback }) {
  // const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  );

  return (
    <Button
      type="button"
      style={{
        // background: "transparent",
        // backgroundColor: isCurrentEventKey ? "pink" : "lavender",
        backgroundColor: "red",
        borderRadius: "0px",
        color: "black",
        padding: "30px 30px",
        textAlign: "left",
        border: "0px",
        borderColor: "red"
      }}
      onClick={decoratedOnClick}
    >
      {children}
    </Button>
  );
}

function ResultsExtraInfo(props) {
  // const { activeFile } = props.highlight;
  console.log(props.data)
  return (
    <div>
      <Accordion>
        <Card style={{ margin: "10px", border: "0px solid", borderRadius: "0px"}}>
          {/* <Card.Header> */}
          <ContextAwareToggle eventKey="0">
            <h5 style={{fontWeight: "bold" }}>Information About your File and Processing</h5>
          </ContextAwareToggle>
          {/* </Card.Header> */}
          <Accordion.Collapse eventKey="0">
            <Card.Body style={{ border: "0px solid", backgroundColor: "red", borderRadius: "0px" }}>
              <h6>Information about your File:</h6>
              <p>Your Data was processed X-Algorithm version: {props.data.algorithm_version} and ML-X version: {props.data.ML_Version}.</p>
              <p>Your data was processed on {props.data.date_processed}.</p>
              <p>The File Identification Number is: {props.data.fileIdentificationNumber} (you can include this email to report a problem with our processing to make it easeir to locate yuor file).</p>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
}

export default ResultsExtraInfo;
