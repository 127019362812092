import React, { Component } from "react";
import Container from "../../../Univeral Components/Container";
import GreyContainer from "../../../Univeral Components/GreyContainer";
import DemoFile from "./DemoFile1.mp4"

import {
  Player,
  ControlBar,
  ReplayControl,
  ForwardControl,
  CurrentTimeDisplay,
  TimeDivider,
  PlaybackRateMenuButton,
  // VolumeMenuButton,
} from "video-react";

export default class FullVideo extends Component {
  render() {
    return (
      <div>
        <Container>
          <div className="square">
            <div className="col-xs-1-12" style={{ textAlign: "center", width: "80%", margin: "auto" }}>
                <Player
                  startTime={0}
                  src = {DemoFile}
                >
                  <ControlBar>
                    <ReplayControl seconds={10} order={1.1} />
                    <ForwardControl seconds={30} order={1.2} />
                    <CurrentTimeDisplay order={4.1} />
                    <TimeDivider order={4.2} />
                    <PlaybackRateMenuButton
                      rates={[5, 2, 1, 0.5, 0.1]}
                      order={7.1}
                    />
                  </ControlBar>
                </Player>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}
