import React from 'react';
import DemoResult from './DemoResult';
import FullVideo from './DemoFullVideo';
import Container from '../../../Univeral Components/Container';
import GreyContainer from '../../../Univeral Components/GreyContainer';
import '~./../video-react/dist/video-react.css'; // import css
import { Helmet } from 'react-helmet';

class UploadResults extends React.Component {
  state = {
    upper: [
      {
        id: 0,
        XScore: 1713,
        Starred: true,
        startTimeMin: '0:00:19',
        startTimeSeconds: 19,
      },
      {
        id: 1,
        XScore: 2043,
        Starred: true,
        startTimeMin: '0:00:52',
        startTimeSeconds: 52,
      },
      {
        id: 2,
        XScore: 1677,
        Starred: false,
        startTimeMin: '0:01:29',
        startTimeSeconds: 89,
      },
      {
        id: 3,
        XScore: 1559,
        Starred: false,
        startTimeMin: '0:02:17',
        startTimeSeconds: 137,
      },
      {
        id: 4,
        XScore: 1535,
        Starred: true,
        startTimeMin: '0:04:40',
        startTimeSeconds: 280,
      },
      {
        id: 5,
        XScore: 1582,
        Starred: false,
        startTimeMin: '0:05:29',
        startTimeSeconds: 329,
      },
      {
        id: 6,
        XScore: 1700,
        Starred: false,
        startTimeMin: '0:06:10',
        startTimeSeconds: 370,
      },
      {
        id: 7,
        XScore: 1778,
        Starred: true,
        startTimeMin: '0:09:27',
        startTimeSeconds: 567,
      },
      {
        id: 8,
        XScore: 1688,
        Starred: false,
        startTimeMin: '0:09:52',
        startTimeSeconds: 592,
      },
      {
        id: 9,
        XScore: 1872,
        Starred: true,
        startTimeMin: '0:12:51',
        startTimeSeconds: 771,
      },
    ],
  };

  // Toggle Complete
  toggleStar = (id) => {};

  render() {
    return (
      <div>
        <Helmet>
          <title>XHighlight | Demo Analyisis</title>
        </Helmet>
        <div className='d-none d-lg-block'>
          <GreyContainer>
            <h2
              style={{
                fontWeight: 'bolder',
                marginTop: '9px',
                padding: '15px 0',
              }}
            >
              Your Highlights
              <span
                style={{
                  fontSize: '30px',
                  float: 'right',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  flexDirection: 'row',
                }}
              >
                XHighlight Demo File.mp4
              </span>
            </h2>
          </GreyContainer>
        </div>
        <div className='d-lg-none'>
          <GreyContainer>
            <h2
              style={{
                fontWeight: 'bolder',
                marginTop: '9px',
                padding: '15px 0 0px 0',
              }}
            >
              Your Highlights:
            </h2>
            <h5
              style={{
                marginTop: '4px',
                padding: '15px 0 15px 0',
              }}
            >
              XHighlight Demo File.mp4
            </h5>
          </GreyContainer>
        </div>
        {/* {console.log(this.state.data.menu.files)} */}
        {/* {console.log("this.state.data.menu.files")} */}

        <Container>
          <div>
            {/* <h1>{console.log("Long")}</h1> */}
            {/* {window.props.map(home => <div>{"test"}</div>)} */}
            <div className='row' style={{ paddingTop: '10px' }}>
              <DemoResult upper={this.state.upper} />
            </div>
          </div>
        </Container>

        <GreyContainer>
          <h2
            style={{
              fontWeight: 'bolder',
              marginTop: '9px',
              padding: '15px 0',
              margin: '30px 0',
            }}
          >
            Full Video
          </h2>
        </GreyContainer>
        <FullVideo url={process.env.REACT_APP_DEMO_VIDEO_URL} />
      </div>
    );
  }
}

export default UploadResults;
