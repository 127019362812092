import React from "react";
import { NavLink } from "react-router-dom";
// import "./Navbar.css";
import { Navbar, Nav, Container } from "react-bootstrap";
import Logo from "../Assets/Page Assets/Xhighlight Logo White X 2.png";

const loginStyle = {
  color: "white",
  backgroundColor: "grey",
  padding: "10px 10px",
  margin: "0px 10px",
  borderRadius: "10px",
};

const NavBar = () => (
  <div>
    <Navbar
      className="red-style nav-opacity nav-bar-size"
      expand="lg"
    >
      <Container>
        <Navbar.Brand href="/">
          <img
            alt=""
            src={Logo}
            height="65px"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto justify-content-end"></Nav>
          <Nav>
            <NavLink to="/" exact activeClassName="active" className="nav-link">
              Home
            </NavLink>
            <NavLink to="/how" activeClassName="active" className="nav-link">
              How It Works
            </NavLink>
            <NavLink to="/why" activeClassName="active" className="nav-link">
              Why XHighlight?
            </NavLink>
            <NavLink to="/upload" activeClassName="active" className="nav-link">
              Upload
            </NavLink>
            <NavLink to="/demo" activeClassName="active" className="nav-link">
              Demo
            </NavLink>
            <NavLink to="/help" activeClassName="active" className="nav-link">
              Help
            </NavLink>
            <NavLink to="/login" activeClassName="active" className="nav-link disabled" style={loginStyle}>
              Login/Sign Up
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  </div>
);

export default NavBar;
