import React, { Component } from "react";

class Demo extends Component {
  render() {
    return (
      <div style={{ paddingBottom: "20px" }}>
        <h1
          style={{ margin: "30px 0 30px 0", paddingTop: "0px" }}
          className="headings"
        >
          Demo - 2 Ways to Try XHighlight
        </h1>
        <div className="row">
          <div className="col-sm-6 ">
            <h3>I. Test using Our Sample File</h3>
            <ol>
              <li>Download this text file.</li>
              <li>Upload it <a href="/demo/upload">here</a>.</li>
              <li>Once uploaded, review the results.</li>
              <li>Explore the results and features our platform offers.</li>
            </ol>
          </div>
          <div className="col-sm-6 ">
            <h3>II. View Results of Our Sample File without Uploading.</h3>
            <div style={{ textAlign: "center" }}>
              <a href="/upload">
                <button
                  style={{
                    border: "none",
                    color: "white",
                    padding: "15px",
                    textAlign: "center",
                    display: "inline-block",
                    fontSize: "20px",
                    marginTop: "20px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    borderRadius: "15px",
                  }}
                  className="btn red"
                >
                  View Results
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Demo;
