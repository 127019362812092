import React from "react";
import "../styles/Style.css";

export const GreyContainer = (props) => (
  <div>
    <div className="light-grey-style">
      <div className="container">{props.children}</div>
    </div>
  </div>
);
export default GreyContainer