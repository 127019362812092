import React from 'react';
import GreyContainer from '../Univeral Components/GreyContainer';
import Container from '../Univeral Components/Container';
import TagLine from './Components/TagLine';
import Advantages from './Custom Page Components/Advantages';
import CreationProcess from './Custom Page Components/CreationProcess';
import HowWorks from './Custom Page Components/HowWorks';
import Demo from './Custom Page Components/Demo';
import DemoOriginal from './Custom Page Components/DemoOriginal';
import GetStartedBottom from './Custom Page Components/GetStartedBottom';
import TypeForm from './Custom Page Components/Typeform';
import TypeformModal from './Custom Page Components/TypeformModal';
import JermaineImage from '../Assets/Custom Assets/Jermaine 1.png';

// Browser Detection https://stackoverflow.com/questions/49328382/browser-detection-in-reactjs

class Home extends React.Component {
  render() {
    return (
      <div>
        <TagLine />
        <GreyContainer>
          <div style={{ padding: 0 }}>
            <div style={{ marginTop: '0px' }}>
              {/* content */}
              <div className='row'>
                <div className='col-lg-7' style={{ marginTop: '30px' }}>
                  <h2 className='headings'>
                    Build Highlight Videos in just a Few Clicks!
                  </h2>
                  <p style={{ fontSize: '25px', padding: '40px 0px 40px 0px' }}>
                    Use our powerful algorithms to find your sports highlights
                    instantaniously.
                  </p>
                  <a href='upload'>
                    <button
                      style={{
                        margin: '20px 0 20px 0',
                        border: 'none',
                        color: 'white',
                        padding: '20px',
                        textAlign: 'center',
                        display: 'inline-block',
                        borderRadius: '30px',
                      }}
                      className='btn red col-11'
                    >
                      <span
                        style={{
                          fontSize: '30px',
                          margin: '5px 0 5px 0',
                          fontWeight: 'bold',
                        }}
                      >
                        Get Started Now
                      </span>
                      <br />
                      <span style={{ padding: '0px', margin: '5px 0 5px 0' }}>
                        Upload now to get started!
                      </span>
                    </button>
                  </a>
                </div>
                <div
                  className='col-lg-5'
                  style={{ margin: '0px', padding: '0px' }}
                >
                  <a href='https://www.youtube.com/watch?v=KuCrJmfSqdc'>
                    <img
                      className='img-responsive'
                      src={JermaineImage}
                      alt='Jermaine Samuels Highlight'
                      width='100%'
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </GreyContainer>
        <Container>
          <div style={{ paddingTop: '30px', textAlign: 'center' }}>
            <h2 className='headings'>
              Sign Up to be Notified when our Beta Launches!
            </h2>
            <div>
              <TypeformModal />
            </div>
          </div>
        </Container>
        <GreyContainer>
          <Advantages />
        </GreyContainer>
        <Container>
          <CreationProcess />
        </Container>
        <GreyContainer>
          <HowWorks />
        </GreyContainer>
        <Container>
          {/* <Demo /> */}
          <DemoOriginal />
        </Container>
        <hr style={{ border: '1px dashed black' }} />
        <GetStartedBottom />
      </div>
    );
  }
}

export default Home;
