import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
// import { Helmet } from "react-helmet";
import Container from "../../Univeral Components/Container";
import styled from "styled-components";
import { Redirect } from "react-router-dom";
import Loading from "../Outcomes Page/Loading";

//This uploads a binary string to the sever but i cant figure out how to decde it.

const getColor = (props) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isDragActive) {
    return "black";
  }
  return "#eeeeee";
};

const getColorBackground = (props) => {
  // if (props.isDragAccept) {
  //   return "green";
  // }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isDragActive) {
    return "#999999";
  }
  return "#fafafa";
};

const DropArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: ${(props) => getColorBackground(props)};
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;
// Original Backgournd COlor //#fafafa;

function DropElement() {
  let dataReturn = null;
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
    // onDropRejected,
    // rejectedFiles,
  } = useDropzone({
    accept: "video/mp4, .mov",
    // accept: "video/mp4, .mov",
  });
  let uploads = [];
  let submit = false;

  const acceptedFilesItems = acceptedFiles.map(
    (file) => (
      <li key={file.path}>
        {/* {console.log(file.path)} */}
        {file.path} - {file.size} bytes
      </li>
    ),
    acceptedFiles.map((file) => uploads.push(file))
  );

  const [state = null, setState] = useState(null);

  async function handleSubmit(event) {
    // event.preventDefault();
    console.log("Submitting uploads");
    console.log("Uploads " + uploads);
    submit = true;
    let formData = new FormData();
    formData.append(uploads[0].name, uploads[0]);
    console.log("Posting...");
    submit = true;
    fetch("http://localhost:8000/post-clip/", {
      method: "POST",
      body: formData,
      enctype: "multipart/form-data",
    }).then((response) => {
      response.json().then((data) => {
        console.log(data.Xresponse);
        // let res = data.Xresponse
        dataReturn = data.Xresponse;
        console.log(dataReturn);
        setState({ state: data.Xresponse });
      });
      console.log(dataReturn);
    });
  }

  if (submit == true) {
    console.log("loading results...");
    return <Loading />;
  }
  if (state == null) {
    return (
      <Container>
        <form>
          <DropArea
            {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
          >
            <input {...getInputProps()} multiple={false} />
            {isDragActive &&
              !isDragReject &&
              "As a Reminder, Only .mp4 and .mov files are accepted. Please make sure to keep your file size under 5 gigs."}
            {isDragReject && "File type not accepted or is too large, sorry!"}
            <h2 style={{ color: "red" }}>
              Drop your video file here for Quick Clip analysis.{" "}
            </h2>
            <p>Drag 'n' drop a video file for Quick Clip analysis.</p>
          </DropArea>
          {acceptedFilesItems.length > 0 && (
            <aside>
              <br />
              <h3>Accepted Files</h3>
              <h5>
                1. {acceptedFiles[0].name} - {acceptedFiles[0].size}
              </h5>
            </aside>
          )}
          <br />
          {acceptedFiles.length > 1 && (
            <div>
              <h3>Notice:</h3>
              <h5>
                For Quick Clips, only 1 file can be uploaded at a time. Only{" "}
                <strong>{acceptedFiles[0].name}</strong> will be uploaded and
                analyzed.
              </h5>
            </div>
          )}
          <div style={{ textAlign: "center" }}>
            {acceptedFiles.length >= 1 && (
              <button
                className="col-11 btn-white"
                type="button"
                onClick={handleSubmit}
                style={{
                  margin: "20px 0 20px 0",
                  border: "none",
                  color: "black",
                  padding: "20px",
                  textAlign: "center",
                  display: "inline-block",
                  borderRadius: "30px",
                }}
                className="btn-white col-11"
              >
                <span
                  style={{
                    fontSize: "30px",
                    margin: "5px 0 5px 0",
                    fontWeight: "bold",
                  }}
                >
                  Analyze Now
                </span>
                <br />
                <span style={{ padding: "0px", margin: "5px 0 5px 0" }}>
                  Upload now to get started!
                </span>
              </button>
            )}
          </div>
        </form>
      </Container>
    );
  } else {
    console.log("state " + state);
    console.log("abc " + dataReturn);
    return <Redirect to={{ pathname: "/clip-results", data: state.state }} />;
  }
  return <Redirect to={{ pathname: "/" }} />;
}

export default DropElement;
