import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Home from '../pages/Home';
import How from '../pages/How';
import Why from '../pages/Why';
import Upload from '../pages/Upload';
// import DemoUpload from '../pages/DemoUpload';
import DemoUpload from '../pages/Demo Folder/Partial Demo/DemoResults';
import Results from '../pages/Results';
import PResults from '../pages/ProgrammingResults';
import ClipResults from '../pages/ClipResults';
import ClipUpload from '../pages/ClipUpload';
import FAQ from '../pages/FAQs';
import Help from '../pages/Help';
import Login from '../pages/Login';
// import Todo from '../pages/todoList';
// import Test from '../pages/Test';
import '../styles/Style.css';
import NoMatch from '../pages/NoMatch';

const Routes = () => (
  <Switch>
    <Route exact path='/' component={Home} />

    <Route path='/how' component={How} />
    <Route path='/why' component={Why} />
    <Route path='/upload' component={Upload} />
    {/* <Route path="/demo/upload" component={DemoUpload} /> */}
    <Route path='/demo' component={DemoUpload} />
    {/* <Route path='/results' component={Results} /> */}
    {/* <Route path='/programming-results' component={PResults} /> */}
    {/* <Route path='/clip' component={ClipUpload} /> */}
    {/* <Route path='/clips' component={ClipUpload} /> */}
    {/* <Route path='/clip-results' component={ClipResults} /> */}
    <Route path='/help' component={Help} />
    <Route path='/faq' component={FAQ} />
    {/* <Route path="/login" component={Login} /> */}

    {/* <Route path="/upload" component={upload} /> */}
    {/* <Route path="/upload/outcomes" component={outcomes} /> */}
    {/* <Route path="/help" component={help} /> */}
    {/* <Route path="/data" component={data} /> */}
    {/* <Route path="/privacy" component={privacy} /> */}

    {/* <Route path="/todo" component={Todo} /> */}
    {/* <Route component={NoMatch}/> */}
    <Route path='/404' component={NoMatch} />
    <Redirect to='/404' />
  </Switch>
);

export default Routes;
