import React, { Component } from "react";

class LoginNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account: false,
    };
  }
  handleClick() {
    this.setState((state) => ({
      account: !state.account,
    }));
  }

  componentDidMount() {
    this.handleClick();
  }

  render() {
    if (this.state.account == true) {
      return (
        <div style={{textAlign: "center" }}>
          <div>
          <h1 style={{ margin: "10px " }}>Login</h1>
          </div>
          <label style={{ marginRight: "10px" }}>Email:</label>
          <input type="text" name="email" placeholder="email" style={{marginLeft: "30px" }}></input>
          <br></br>
          <label style={{ marginRight: "10px" }}>Password: </label>
          <input type="password" name="password" placeholder="password"></input>

          <br></br>
          <div style={{ textAlign: "right" }}>
            <button
              style={{
                border: "none",
                fontWeight: "bolder",
                color: "white",
                padding: "10px",
                textAlign: "center",
                fontSize: "20px",
                borderRadius: "15px",
              }}
              className="btn red"
            >
              Login
            </button>
          </div>
          <br></br>
          <button
            onClick={() => this.setState({ account: !this.state.account })}
            className="btn"
            style={{
              color: "white",
              textAlign: "center",
              fontSize: "15px",
              borderRadius: "15px",
            }}
          >
            Need an Account? Sign Up for Free Now!
          </button>
        </div>
      );
    } else {
      return (
        <div>
          <h1 style={{ margin: "5px " }}>Sign-Up</h1>
          <label style={{ marginRight: "10px" }}>Email: </label>
          <input type="text" name="email" placeholder="email"></input>
          <br></br>
          <br></br>
          <label style={{ marginRight: "10px" }}>Password: </label>
          <input type="password" name="password" placeholder="password"></input>
          <br></br>
          <label style={{ marginRight: "10px" }}>Re-Enter Password: </label>
          <input type="password" name="password" placeholder="password"></input>

          <br></br>
          <div style={{ textAlign: "right" }}>
            <button
              style={{
                border: "none",
                fontWeight: "bolder",
                color: "white",
                padding: "10px",
                textAlign: "center",
                fontSize: "20px",
                borderRadius: "15px",
              }}
              className="btn red"
            >
              Sign Up
            </button>
          </div>
          <br></br>
          <button
            onClick={() => this.setState({ account: !this.state.account })}
            className="btn"
            style={{
              color: "white",
              textAlign: "center",
              fontSize: "15px",
              borderRadius: "15px",
            }}
          >
            Already Have a login? Sign-In
          </button>
        </div>
      );
    }
  }
}

export default LoginNew;
