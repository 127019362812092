import React, { Component } from "react";
import HowItWorksFAQ from "./HowItWorksFAQ";
import AccordionElementsChild from "./AccordionElementsChild";

class FAQCombined extends Component {
  render() {
    return (
      <div>
        <HowItWorksFAQ />
        <AccordionElementsChild
          question="How long does it take for my video to be analyzed?"
          answer="
              Not long! Once your file is uploaded, most videos will be analyzed
              and processed in less than a minute by our powerful mathematical
              models and Machine Learning Models."
        />
        <AccordionElementsChild
          question="Who can see my videos?"
          answer=" Once you uplaod your video, we securely store your video for a
          short period of time. Unique videos may be stored for longer
          periods of time to help train our model. If you have any specific
          questoins, please contact us at contact@xhighlight.com.
        "
        />
        <AccordionElementsChild
          question="What happens after I leave the page?"
          answer=" As with your video, the results of your video are stored securely
          on our servers. No one outside of XHighlight has access to your
          results. We do not have accounts, so the best way to see your
          results agian is to uplaod your file again."
        />
        <AccordionElementsChild
          question="Does it only work for Basketball?"
          answer="Currently, the machine learning portion of our technology is only
          optimized for basketball, however, the mathematical portion of it
          also works for other sports. Due to current optomizations, this
          may lead to the missed highights, cut highlights more often. We
          plan to optimize it for other sports soon."
        />
        <AccordionElementsChild
          question="What about other sports such as Baseball, Football, Soccer,
          Lacrosse, Cricket, etc?"
          answer="While we currently only officially support basketball, we
          encourage you to to try other sports. We are always refining our
          technology and hope to support additional sports soon. Your
          Highlights may be less accurate due to a lack of Machine Learning
          Training however some of our models do work on other sports."
        />
      </div>
    );
  }
}

export default FAQCombined;
