import React from "react";
import GreyContainer from "../Univeral Components/GreyContainer";
import Container from "../Univeral Components/Container";
// import TagLine from "./Components/TagLine";
import Advantages from "./Custom Page Components/Advantages";
import CreationProcess from "./Custom Page Components/CreationProcess";
import Demo from "./Custom Page Components/DemoOriginal";
import GetStartedBottom from "./Custom Page Components/GetStartedBottom";
import { Helmet } from "react-helmet";

class Why extends React.Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>Why XHighlight</title>
        </Helmet>
        {/* <TagLine /> */}
        <div style={{ margin: "60px 0 50px 0", textAlign: "center" }}>
          <h2 className="mt-4 text-center">
            <strong>Why XHighlight Over Services?</strong>
          </h2>
        </div>
        <GreyContainer>
          <Advantages />
        </GreyContainer>
        <Container>
          <CreationProcess />
        </Container>
        <GreyContainer>
          <div style={{ padding: "7px 0" }}>
            <Demo />
          </div>
        </GreyContainer>
        {/* <hr style={{ border: "1px dashed black" }} /> */}
        <div style={{ marginTop: "20px" }}>
          <GetStartedBottom />
        </div>
      </div>
    );
  }
}

export default Why;
