import React, { Component } from "react";
import { Accordion, Card, Button } from "react-bootstrap";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import { useContext } from "react";

function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <Button
      type="button"
      style={{
        background: "transparent",
        backgroundColor: isCurrentEventKey ? "pink" : "lavender",
        color: "black",
        padding: "30px 30px",
        textAlign: "left",
        border: "0px",
      }}
      onClick={decoratedOnClick}
    >
      {children}
    </Button>
  );
}

function AccordianElement(props) {
  return (
    <div>
      <Accordion>
        <Card style={{ margin: "10px" }}>
          {/* <Card.Header> */}
          <ContextAwareToggle eventKey="0">
            <h4>{props.question}</h4>
          </ContextAwareToggle>
          {/* </Card.Header> */}
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              {props.answer}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
}

export default AccordianElement;
