import React from "react";
import GreyContainer from "../Univeral Components/GreyContainer";
import Container from "../Univeral Components/Container";
import TagLine from "./Components/TagLine";
// import DropElement from "./Custom Page Components/DropElement";
import HowWorks from "./Custom Page Components/HowWorks";
// import DropElement from "./Custom Page Components/DropElement2
// import DropElement from "./Custom Page Components/DropElement7";
// import HTMLInputElement from "./Custom Page Components/HTMLInputElement";
import DropElementClip from "./Custom Page Components/DropElementClip";
import { Helmet } from 'react-helmet';

class Upload extends React.Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>XHighlight Clip Uploader</title>
        </Helmet>
        {/* <TagLine /> */}
        <Container>
          <h2 className="mt-4 headings" style={{ paddingBottom: "12px" }}>
            Quick Clip: Quickly Locate Single Highlights.
          </h2>
        </Container>
        <div style={{ backgroundColor: "red", padding: "25px" }}>
          <Container>
            <DropElementClip />
          </Container>
        </div>
        <GreyContainer>
          <div style={{ padding: "20px 0" }}>
            <h2
              className="mt-4 headings"
              style={{ paddingBottom: "10px", color: "black" }}
            >
              What is Quick Clip?
            </h2>
            <h3>
              {/* <strong> */}
              Quick Clip is a quick way to locate the first highlight in a
              video.
              {/* </strong> */}
            </h3>
            <p>
              Quick Clip is designed to take a look at short video files,
              typically 5 minutes or shorter, find that first highlight, and
              quickly create a short clip of that highlight. Quick Clip
              analyizes your video file and quickly finds the location of the
              highlight. Then, it gives you quick options to edit the beginning
              and end of your file add your logo to the bottom of the video, and
              add a splash screen with your logo at the end of the video. You
              can then download the video and post it to Twitter, Instagram, or
              any other social media channels.
            </p>
          </div>
        </GreyContainer>
        <Container>
          <div style={{ padding: "20px 0" }}>
            <h2
              className="mt-4 headings"
              style={{ paddingBottom: "10px", color: "black" }}
            >
              How Quick Clip Works
            </h2>
            <p>
              Quick Clip is powered by the same powerful algorithms as Game
              Highlight Detection, but is instead optimized for finding a single
              highlight. This feature, which can also be used within Game
              Highlight Detection (coming soon), is designed for finding the
              first highlight within a video file. You can then quickly download
              that video file and share it to social media.
            </p>
          </div>
        </Container>
      </div>
    );
  }
}

export default Upload;
