import React from "react";
import { Accordion, Card, Button } from "react-bootstrap";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import { useContext } from "react";

function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <Button
      type="button"
      style={{
        background: "transparent",
        backgroundColor: isCurrentEventKey ? "pink" : "lavender",
        color: "black",
        padding: "30px 30px",
        textAlign: "left",
        border: "0px",
      }}
      onClick={decoratedOnClick}
    >
      {children}
    </Button>
  );
}

function HowItWorksFAQ() {
  return (
    <div>
      <Accordion>
        <Card style={{ margin: "10px" }}>
          {/* <Card.Header> */}
          <ContextAwareToggle eventKey="0">
            <h4>How does XHighlight Work?</h4>
          </ContextAwareToggle>
          {/* </Card.Header> */}
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              XHighlight uses proprietary mathematical models to predict and
              find where highlights are located. Machine learning is then used
              to validate the location of these Highlights. Your files are
              uploaded and analyzed by our models without any human
              interactions, humans our only review to optimize the algorithm.
              Want to learn more, check out our <a href="/how">How It Works</a>{" "}
              page.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        </Accordion>
    </div>
  );
}

export default HowItWorksFAQ;
