import React, { Component } from "react";
import Highlight from "./Highlight";
// import { Helmet } from "react-helmet";

class Result extends Component {
  render() {
      return this.props.upper.map((highlight) => (
        <Highlight
          key={highlight.id}
          highlight={highlight}
          file_name={this.props.file_name}
          toggleStar={this.props.toggleStar}
        />
    ));
  }
}

export default Result;
