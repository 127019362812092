import React from "react";
import LoginNew from "./Custom Page Components/LoginNew"

class Help extends React.Component {
  render() {
    return (
      <div
        style={{
          display: "flex",
          minHeight: "100vh",
          width: "100%",
          padding: "0",
          margin: "0",
          alignItems: "center",
          justifyContent: "center",
          flexDirectoin: "column",
        }}
      >
        <div
          style={{
            backgroundColor: "lightGrey",
            // color: "white",
            padding: "60px",
            borderRadius: "30px ",
            // textAlign: "center",
            boxShadow: "7px 10px 5px 0px rgba(50, 50, 50, 0.75)",
          }}
        >
        <div style={{textAlign: "center" }}>
          <img
            alt="XHighlight Logo"
            src={require("../Assets/Page Assets/XHighlight Logo Transparent Background.png")}
            height="100px"

          />
          <LoginNew/>
          </div>
        </div>
      </div>
    );
  }
}

export default Help;
