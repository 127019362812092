import React, { Component } from 'react';
import AdvantagesDesktop from '../../Assets/Custom Assets/4 Advantages.png';
import XHighlightLogo from '../../Assets/Page Assets/Xhighlight Logo White X 2.png';
import AdvantagesSpeed from '../../Assets/Custom Assets/4 Advantages - Speed.png';
import AdvantagesSocial from '../../Assets/Custom Assets/4 Advantages - Social.png';
import AdvantagesCost from '../../Assets/Custom Assets/4 Advantages - Low Cost.png';
import AdvantagesAccuracy from '../../Assets/Custom Assets/4 Advantages - Accuracy.png';

class Advantages extends Component {
  render() {
    return (
      <div>
        {/*  LARGE SCREENS Four Advantages Toghether */}
        <div className='d-none d-xl-block'>
          <div className='increase-spacing'>
            {/* content */}
            <div style={{ marginTop: '30px', paddingTop: '30px' }}>
              <h2 className='headings'>XHighlight Advantages: What You Get</h2>
            </div>
            <div className='row'>
              <div className='col-xl-4 advantage-right'>
                <h3>Speed</h3>
                <p>
                  XHighlight's technology spepeds up the creation process for
                  videos
                </p>
                <h3 style={{ marginTop: '150px' }}>Accuracy</h3>
                <p>Using our technology, human bias is completely removed</p>
              </div>
              <div className='col-xl-4'>
                <img src={AdvantagesDesktop} className='img-fluid' alt={''} />
              </div>
              <div className='col-xl-4'>
                <h3>Low Cost</h3>
                <p>
                  Does not require multiple employees to watch the video
                  multiple times.
                </p>
                <h3 style={{ marginTop: '150px' }}>
                  Social Media{' '}
                  <span style={{ fontSize: '12px' }}>(Coming Soon)</span>
                </h3>
                <p>
                  XHighlight's Social Media intergation allows or near-instant
                  posting to social media platforms such as Youtube and
                  Instagram
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* SMALL SCREENS Four Advantages Seperated */}
        <div className='d-xl-none'>
          <div className='increase-spacing'>
            <h2 className='headings'>XHighlight Advantages</h2>
            <div
              className='row'
              style={{ display: 'block', textAlign: 'center', maxWidth: 900 }}
            >
              <picture>
                <img
                  className='img-fluid center'
                  src={AdvantagesDesktop}
                  alt='The four advantages of XHighlight'
                />
                {/* content */}
              </picture>
              {/* <img src="../Assets/Page Assets/Xhighlight Logo White X 2.png" alt="" style="margin-left: auto; margin-right: auto; margin-top: 30px; height: 20px"> */}
            </div>
          </div>
        </div>
        <div
          className='red-style d-xl-none'
          style={{ margin: 'bottom', padding: '5px 0 5px 0' }}
        >
          <img
            src={XHighlightLogo}
            alt={''}
            height='32px'
            style={{ marginLeft: '100px' }}
          />
        </div>
        <div className='light-grey-style d-xl-none' style={{}}>
          <div className='increase-spacing'>
            <div style={{ marginTop: '0px', paddingTop: '30px' }}>
              <div className='row'>
                <div className='col-sm-5'>
                  <img src={AdvantagesSpeed} className='img-fluid' alt={''} />
                </div>
                <div className='col-sm-7' style={{ marginTop: '40px' }}>
                  <h3>Speed</h3>
                  <p>
                    XHighlight's technology speeds up the creation process for
                    videos. Unlike other compeditors, our solution is powered
                    completely on algorthms. This enables us to prvide your
                    Highlights instantly without a human reviewing your video to
                    get you your results.
                  </p>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-7' style={{ marginTop: '80px' }}>
                  <h3>Accuracy</h3>
                  <p>
                    Using our technology, human bias is completely removed. By
                    removing humans, this removes bias' and allows you to get
                    the best moments from the video.
                  </p>
                </div>
                <div className='col-sm-5' style={{ marginTop: '20px' }}>
                  <img
                    src={AdvantagesAccuracy}
                    className='img-fluid'
                    alt={''}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-5'>
                  <img src={AdvantagesCost} className='img-fluid' alt={''} />
                </div>
                <div className='col-sm-7' style={{ marginTop: '50px' }}>
                  <h3>Low Cost</h3>
                  <p>
                    By not involving humans, our solution is able to
                    substancially reduce the cost but due to our sophistecated
                    technolgies, we are still able to provide you with the best
                    results.
                  </p>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-7' style={{ marginTop: '60px' }}>
                  <h3>
                    Social Media{' '}
                    <span style={{ fontSize: '12px' }}>(Coming Soon)</span>
                  </h3>
                  <p>
                    Our Social Media intergation allows or near-instant posting
                    to social media platforms such as Youtube and Instagram so
                    you can easily share and grow your audience.
                  </p>
                </div>
                <div className='col-sm-5'>
                  <img src={AdvantagesSocial} className='img-fluid' alt={''} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Advantages;
