import React from "react";
import ClipResult from "./Outcomes Page/Clips/ClipResult";
import FullVideo from "./Outcomes Page/FullVideo";
import Loading from "./Outcomes Page/Loading";
import Container from "../Univeral Components/Container";
import GreyContainer from "../Univeral Components/GreyContainer";
import "~./../video-react/dist/video-react.css"; // import css
import axios from "axios";
import { Redirect } from "react-router-dom";

class UploadResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    //TODO: make data: null
      data:   {
        "id": "CLIP_Highlight at End - 9e88102eda2149258e5ffecae3a30512.mp4.jdata",
        "name": "CLIP_Highlight at End - 9e88102eda2149258e5ffecae3a30512.mp4.jdata",
        "activeFile": 0,
        "menu": "Highlight at End.mp4",
        "uniqueFiles": [
            "Highlight at End - 9e88102eda2149258e5ffecae3a30512.mp4"
        ],
        "thisFilesData": {
            "clipResult": {
                "end": {
                    "end_notes": "Stop Detected",
                    "endTimeMin": "0:00:35",
                    "endTimeSeconds": 35
                },
                "start": {
                    "startTimeMin": "0:00:21",
                    "startTimeSeconds": 21
                }
            }
        },
        "algorithm_version": "0.900",
        "date_processed": "2020-10-31T17:55:21.543923-04:00"
    },
      cancel: false,
      counter: 0,
      complete: false,
      activeNumber: 0,
      newUrl: null,
    };
  }

  loadData() {
    const data_page = this.props.location.data;
    // console.log(data_page);
    if (data_page) {
      let data_page_num_of_files = data_page;
      // console.log(data_page_num_of_files);
      data_page_num_of_files = data_page_num_of_files.split("***")[0];
      // console.log(typeof data_page_num_of_files)
      // let data_page_url = data_page;
      // data_page_url = data_page_url.split("***")[1];
      let max_count = 900; //parseInt(data_page_num_of_files) * 180;
      if (this.state.counter <= max_count && this.state.complete === false) {
        console.log(max_count);
        // Need to change this to be some sort of multiple of how many files
        console.log("Tries: " + this.state.counter);
        // const data_page = this.props.location.data;
        let data_page_url = data_page;
        console.log(data_page_url);
        data_page_url = data_page_url.split("***")[1];
        // console.log("here");
        console.log(data_page_url);

        (async () => {
          let apiResponse = null;
          let data_page_url_no_space = data_page_url.replaceAll(" ", "%20");
          console.log("TESTING");
          let checkUrl =
            "http://localhost:8000/api/data/" +
            "CLIP_" +
            data_page_url_no_space +
            ".jdata";
          // "http://localhost:8000/api/data/WrightWay%20Skills%20vs%20Gauchos%20-%20Alex%20Stoddard%202019%20%234-7qddfOrr6dA%20-%20d01a097631574601bbfd0dad9f67dbc1.mp4.jdata";
          console.log(checkUrl);
          try {
            apiResponse = await axios.get(checkUrl);
          } catch (err) {
            apiResponse = err.response;
          } finally {
            if (apiResponse.status === 200) {
              console.log("Success");
              this.state.complete = true;
              axios.get(checkUrl).then((response) => {
                this.setState({ data: response.data });
              });
            } else if (apiResponse.status === 404) {
              console.log("Fail retrying...");
              console.log("error code: " + apiResponse.status);
              this.state.counter += 1;
              setTimeout(() => this.loadData(), 1500);
            } else {
              this.state.counter += 1;
              console.log("error code: " + apiResponse.status);
              setTimeout(this.loadData(), 1500);
            }
          }
        })();
      } else {
        console.log(data_page);
        console.log("stopped request took too long");
        this.cancel = true;
      }
    }
  }

  componentDidMount() {
    this.loadData();
  }

  // this.state.data.menu.files
  selectResultsData = async (page) => {
    // this.setState({ state: null });
    console.log(page);
    this.setState({ data: null });
    console.log(this.state.activeNumber[page]);
    this.setState({ newUrl: this.state.activeNumber[page] });
    this.selectResults(page);
  };

  selectResults = async () => {
    // console.log(this.state.activeNumber[page]);
    // let unique_name_file = this.state.activeNumber[page];
    console.log(this.state.newUrl);
    let data_page_url = this.state.newUrl;
    (async () => {
      let apiResponse = null;
      let checkUrl =
        "http://localhost:8000/api/data/" + data_page_url + ".jdata";
      console.log(checkUrl);
      try {
        apiResponse = await axios.get(checkUrl);
      } catch (err) {
        apiResponse = err.response;
        console.log(apiResponse);
      } finally {
        if (apiResponse.status === 200) {
          console.log("Success");
          this.state.complete = true;
          axios.get(checkUrl).then((response) => {
            this.setState({ data: response.data });
          });
        } else if (apiResponse.status === 404) {
          console.log("Fail retrying...");
          console.log("error code: " + apiResponse.status);
          this.state.counter += 1;
          console.log(this.state.data);
          setTimeout(() => this.selectResults(), 1500);
        } else {
          this.state.counter += 1;
          console.log(this.state.data);
          console.log("error code: " + apiResponse.status);
          console.log("Fail retrying... (not a 404)");
          setTimeout(this.selectResults(), 1500);
        }
      }
    })();
  };

  render() {
    const { data } = this.state;
    //TODO: Uncomment this
    // if (!this.props.location.data) {
    //   console.log(
    //     "redirecting to upload page because no file has been uploaded (and data is empty)"
    //   );
    //   return <Redirect to={{ pathname: "/clip" }} />;
    // }
    if (!this.state.data) {
      const data_page = this.props.location.data;
      console.log(data_page);
      console.log(!this.state.data);
      console.log("loading results...");
      return (
        <div>
          {console.log(data)}
          {console.log("Loading full file and data")}
          {console.log(!this.state.data)}
          <Loading />;
        </div>
      );
    } else {
      return (
        <div>
          {console.log(data)}
          {console.log("Loading full file and data")}
          {console.log(!this.state.data)}
          <div className="d-none d-lg-block">
            <GreyContainer>
              <h2
                style={{
                  fontWeight: "bolder",
                  marginTop: "9px",
                  padding: "15px 0",
                }}
              >
                Quick Clip
                {/* {context.unique_name}*/}
                {/* { console.log(context.unique_name) } */}
                <span
                  style={{
                    fontSize: "30px",
                    float: "right",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    flexDirection: "row",
                  }}
                >
                  {" "}
                  {this.state.data.menu}
                </span>
              </h2>
            </GreyContainer>
          </div>
          <div className="d-lg-none">
            <GreyContainer>
              <h2
                style={{
                  fontWeight: "bolder",
                  marginTop: "9px",
                  padding: "15px 0 0px 0",
                }}
              >
                Your Highlights:
              </h2>
              <h5
                style={{
                  marginTop: "4px",
                  padding: "15px 0 15px 0",
                }}
              >
                {this.state.data.activeFile}
              </h5>
            </GreyContainer>
          </div>
          <Container>
            <ClipResult
              start={this.state.data.thisFilesData.clipResult.start}
              end={this.state.data.thisFilesData.clipResult.end}
              file_name={this.state.data.uniqueFiles[0]}
            />
          </Container>

          {/* <FullVideo file_name={this.state.data.uniqueFiles[0]} /> */}
        </div>
      );
    }
  }
}

export default UploadResults;
