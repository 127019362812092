import React, { Component } from "react";
import Container from "./../../Univeral Components/Container";

import {
  Player,
  ControlBar,
  ReplayControl,
  ForwardControl,
  CurrentTimeDisplay,
  TimeDivider,
  PlaybackRateMenuButton,
  // VolumeMenuButton,
} from "video-react";

export default class FullVideo extends Component {
  render() {

    let src_base_url = "http://localhost:3001/uploads/"
    let src_sub_url = this.props.file_name

    
    src_sub_url = src_sub_url.replace(" ", "%20")

    // console.log(src_sub_url)
    // console.log("full" + src_base_url + src_sub_url)

    return (
      <div>
        <Container>
          <div className="square">
            <div className="col-xs-1-12" style={{ textAlign: "center", width: "80%", margin: "auto" }}>
                <Player
                  startTime={0}
                  // src={require("./../../Assets/Custom Assets/WrightWay Skills vs Team RIO HGSL-JTKBJ0fTxHc.mp4")}
                  src={ src_base_url + src_sub_url }
                >
                  <ControlBar>
                    <ReplayControl seconds={10} order={1.1} />
                    <ForwardControl seconds={30} order={1.2} />
                    <CurrentTimeDisplay order={4.1} />
                    <TimeDivider order={4.2} />
                    <PlaybackRateMenuButton
                      rates={[5, 2, 1, 0.5, 0.1]}
                      order={7.1}
                    />
                  </ControlBar>
                </Player>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}
