import React, { Component } from 'react';
// import { NavLink } from "react-router-dom";
import BackgroundImage from '../Assets/Page Assets/footer-top.png';
import XHighlightLogo from '../Assets/Page Assets/Xhighlight Logo White X 2.png';

class footer extends Component {
  backgroundFooterImage = {
    backgroundImage: `url(${BackgroundImage})`,
    margin: '0',
    marginBottom: '-1px',
    height: '175px',
    backgroundPosition: 'center',
    backgroundRepeat: 'repeat',
    backgroundSize: 'cover',
    position: 'relative',
    // maxWidth: "100%"
  };

  render() {
    return (
      <div>
        <div style={{ display: 'auto', marginTop: '25px' }}>
          <p style={this.backgroundFooterImage}></p>

          {/*The Red "graph"*/}
          <footer className='page-footer font-small red-style pt-4 bottom'>
            {/* Footer Links */}
            <div className='container text-center text-md-left'>
              {/* Grid row */}
              <div className='row'>
                {/* Grid column */}
                <div className='col-md-6 mt-md-0 mt-3 text-center'>
                  {/* Content */}
                  <h5>
                    The Fastest and Easiest way to make your Sports Highlight
                    Videos.
                  </h5>
                  <p style={{ lineHeight: '4px' }}></p>
                  <div>
                    <a href='/'>
                      <img
                        alt='XHighlight Logo'
                        src={XHighlightLogo}
                        width='80%'
                        height='auto'
                      />
                    </a>
                  </div>
                </div>
                {/* Grid column */}
                <hr className='clearfix w-100 d-md-none pb-3' />
                <div className='col-md-2 mb-md-0 mb-2'>
                  {/* Links */}
                  <h5 className='text-uppercase'>About Us</h5>
                  <div className='navbar-light'>
                    <ul className='navbar-nav ml-auto'>
                      <li className='nav-item'>
                        <a className='nav-link disabled' href='/team'>
                          Team
                        </a>
                      </li>
                      <li className='nav-item'>
                        <a className='nav-link' href='/help'>
                          Contact Us
                        </a>
                      </li>
                      <li className='nav-item'>
                        <a className='nav-link' href='/faq'>
                          FAQ
                        </a>
                      </li>
                      {/* <li class="nav-item">
              <a class="nav-link" href="#">FAQ</a>
            </li> */}
                    </ul>
                  </div>
                </div>
                {/* Grid column */}
                <div className='col-md-2 mb-md-0 mb-2'>
                  {/* Links */}
                  <h5 className='text-uppercase'>Data</h5>
                  {/* style="visibility: hidden" */}
                  <div className='navbar-light'>
                    <ul className='navbar-nav ml-auto'>
                      <li className='nav-item'>
                        <a className='nav-link disabled' href='/data'>
                          Your Data
                        </a>
                      </li>
                      <li className='nav-item'>
                        <a className='nav-link disabled' href='/data-privacy'>
                          Privacy and Your Data
                        </a>
                      </li>
                      <li className='nav-item'>
                        <a className='nav-link disabled' href='/terms'>
                          Terms
                        </a>
                      </li>
                      <li className='nav-item'>
                        <a className='nav-link disabled' href='/privacy'>
                          Privacy Policy &amp; Compliance
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/* Grid column */}
                </div>
                {/* Grid column */}
                <div className='col-md-2 mb-md-0 mb-2'>
                  {/* Links */}
                  <h5 className='text-uppercase'>More</h5>
                  <div className='navbar-light'>
                    <ul className='navbar-nav ml-auto'>
                      <li className='nav-item'>
                        <a className='nav-link ' href='/'>
                          Home
                        </a>
                      </li>
                      <li className='nav-item'>
                        <a className='nav-link ' href='/how'>
                          How It Works
                        </a>
                      </li>
                      {/* <li className="nav-item">
                        <a className="nav-link" href="#">
                          Reliability
                        </a>
                      </li> */}
                      <li className='nav-item'>
                        <a className='nav-link ' href='/why'>
                          Why XHighlight
                        </a>
                      </li>
                      <li className='nav-item'>
                        <a className='nav-link' href='/help'>
                          Support
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* Grid row */}
              </div>
              {/* Footer Links */}
              {/* Copyright */}
              <div className='footer-copyright text-center py-3'>
                &copy; {new Date().getFullYear()} -{' '}
                <a href='/' style={{ color: '#E8E8E8' }}>
                  Alvie Stoddard
                </a>
                <p>All Rights Reserved</p>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default footer;
