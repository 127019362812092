import React from "react";
import Result from "./Outcomes Page/Result";
import FullVideo from "./Outcomes Page/FullVideo";
import TopBarSelector from "./Outcomes Page/TopBarSelector3";
import TopBarSelectorNoClick from "./Outcomes Page/TopBarSelectorNoClick";
import Loading from "./Outcomes Page/Loading";
import Container from "../Univeral Components/Container";
import GreyContainer from "../Univeral Components/GreyContainer";
import "~./../video-react/dist/video-react.css"; // import css
import axios from "axios";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

class UploadResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      cancel: false,
      counter: 0,
      complete: false,
      activeNumber: 0,
      newUrl: null,
      redirect_needed: false,
      unique_name_stored: localStorage.getItem("unique_name_stored") || null,
    };
  }

  loadData() {
    console.log(this.props.location.data);
    console.log(this.state.unique_name_stored);
    const data_page = this.props.location.data;
    // console.log(data_page);
    if (data_page) {
      let data_page_num_of_files = data_page;
      // console.log(data_page_num_of_files);
      data_page_num_of_files = data_page_num_of_files.split("***")[0];
      // console.log(typeof data_page_num_of_files)
      // let data_page_url = data_page;
      // data_page_url = data_page_url.split("***")[1];
      // let max_count = 9 //900; //parseInt(data_page_num_of_files) * 180;
      let max_count = 900;

      if (this.state.counter <= max_count && this.state.complete === false) {
        console.log(max_count);
        // Need to change this to be some sort of multiple of how many files
        console.log("Tries: " + this.state.counter);
        // const data_page = this.props.location.data;
        let data_page_url = data_page;
        console.log(data_page_url);
        data_page_url = data_page_url.split("***")[1];
        // console.log("here");
        console.log(data_page_url);

        (async () => {
          let apiResponse = null;
          let data_page_url_no_space = data_page_url.replaceAll(" ", "%20");
          console.log("TESTING");
          let checkUrl =
            "http://localhost:8000/api/data/" +
            data_page_url_no_space +
            ".jdata";
          // "http://localhost:8000/api/data/WrightWay%20Skills%20vs%20Gauchos%20-%20Alex%20Stoddard%202019%20%234-7qddfOrr6dA%20-%20d01a097631574601bbfd0dad9f67dbc1.mp4.jdata";
          console.log(checkUrl);
          try {
            apiResponse = await axios.get(checkUrl);
          } catch (err) {
            apiResponse = err.response;
          } finally {
            if (apiResponse.status === 200) {
              console.log("Success");
              this.state.complete = true;
              axios.get(checkUrl).then((response) => {
                this.setState({ data: response.data });
              });
            } else if (apiResponse.status === 404) {
              console.log("Fail retrying...");
              console.log("error code: " + apiResponse.status);
              this.state.counter += 1;
              setTimeout(() => this.loadData(), 1500);
            } else {
              this.state.counter += 1;
              console.log("error code: " + apiResponse.status);
              setTimeout(this.loadData(), 1500);
            }
          }
        })();
      } else {
        console.log(data_page);
        console.log("stopped request took too long");
        this.cancel = true;
      }
    }
  }

  async componentDidMount() {
    if (
      this.props.location.data ||
      this.state.unique_name_stored ||
      localStorage.getItem("unique_name_stored")
    ) {
      console.log("I did mount as " + this.props.location.data + ".");
      // console.log(this.state.unique_name_stored.indexOf("***") === -1)
      // if(this.state.unique_name_stored.indexOf("***") === -1) {
      //   console.log("no no no")
      //   localStorage.setItem('unique_name_stored', this.props.location.data);
      //   this.setState({ unique_name_stored: this.props.location.data });
      //   console.log(this.state.unique_name_stored)
      // } else{
      //   this.setState({ unique_name_stored: localStorage.getItem('unique_name_stored')})
      //   this.props.location.data = this.state.unique_name_stored
      //   console.log("abc")
      // }
      // console.log(this.state.unique_name_stored.indexOf("***") != -1)
      if (this.props.location.data !== null) {
        console.log("local Storage whipped");
        await localStorage.setItem("unique_name_stored", "a");
        await this.setState({ unique_name_stored: "a" });
        console.log("unique_name_stored " + this.state.unique_name_stored);
      }
      console.log(this.props.location.data != null);
      console.log(this.state.unique_name_stored);
      if (this.state.unique_name_stored.indexOf("***") !== -1) {
        this.setState({
          unique_name_stored: localStorage.getItem("unique_name_stored"),
        });
        this.props.location.data = this.state.unique_name_stored;
        console.log("abc");
      } else {
        console.log("no no no");
        localStorage.setItem("unique_name_stored", this.props.location.data);
        this.setState({ unique_name_stored: this.props.location.data });
        console.log(this.state.unique_name_stored);
      }

      console.log(this.state.unique_name_stored);

      // this.setState(
      //   {
      //     unique_name_stored: this.props.location.data,
      //   },
      //   console.log(unique_name_stored_2),
      //   () => {
      //     localStorage.setItem("unique_name_stored", this.props.location.data);
      //   }
      // );
      this.loadData();
    } else {
      // this.setState({ redirect_needed: true})
    }
  }

  toggleStar(id) {
    //!TODO: change with PUT Request on the server
    // this.setState({
    // highlights: this.state.data.thisFilesData.upper.map((highlight) => {
    //   if (highlight.id === id) {
    //     // console.log("hellow");
    //     highlight.Starred = !highlight.Starred;
    //   }
    //   return highlight;
    // }),
    // });
  }

  getMenuUnique = () => {
    let before = 0;
    let after = 0;
    let active = this.state.data.activeFile;
    let menu = this.state.data.uniqueFiles.files;
    // let menu = ""
    console.log(this.state.data.uniqueFiles);
    console.log(menu);
    console.log("running");

    if (active === 0) {
      before = menu.length;
    } else before = active;
    if (active === menu.length - 1) {
      after = 0;
    } else after = active + 1;
    console.log("fun done");
    // console.log(this.state.data.menu)
    // console.log(before)
    return [menu[before - 1], menu[after]];
  };

  // this.state.data.menu.files
  selectResultsData = async (page) => {
    // this.setState({ state: null });
    console.log(page);
    this.setState({ data: null });
    let both_unique_name = await this.getMenuUnique();
    this.setState({ activeNumber: both_unique_name });
    console.log(this.state.activeNumber[page]);
    this.setState({ newUrl: this.state.activeNumber[page] });

    this.selectResults(page);
  };
  selectResults = async () => {
    // console.log(this.state.activeNumber[page]);
    // let unique_name_file = this.state.activeNumber[page];
    console.log(this.state.newUrl);
    let data_page_url = this.state.newUrl;
    (async () => {
      let apiResponse = null;
      let checkUrl =
        "http://localhost:8000/api/data/" + data_page_url + ".jdata";
      console.log(checkUrl);
      try {
        apiResponse = await axios.get(checkUrl);
      } catch (err) {
        apiResponse = err.response;
        console.log(apiResponse);
      } finally {
        if (apiResponse.status === 200) {
          console.log("Success");
          this.state.complete = true;
          axios.get(checkUrl).then((response) => {
            this.setState({ data: response.data });
          });
        } else if (apiResponse.status === 404) {
          console.log("Fail retrying...");
          console.log("error code: " + apiResponse.status);
          this.state.counter += 1;
          console.log(this.state.data);
          setTimeout(() => this.selectResults(), 1500);
        } else {
          this.state.counter += 1;
          console.log(this.state.data);
          console.log("error code: " + apiResponse.status);
          console.log("Fail retrying... (not a 404)");
          setTimeout(this.selectResults(), 1500);
        }
      }
    })();
  };

  render() {
    const { data } = this.state;
    // if (this.props.location.data) {
    //   console.log("this is runnign")
    //   this.loadData();
    // }
    // if (!this.props.location.data) {
    //   console.log(
    //     "redirecting to upload page because no file has been uploaded (and data is empty)"
    //   );
    //   return <Redirect to={{ pathname: "/upload" }} />;
    // }
    // if (!this.state.redirect_needed === true) 
    //   return <Redirect to={{ pathname: "/upload" }} />;

    if (!this.state.data) {
      const data_page = this.props.location.data;
      console.log(data_page);
      console.log(!this.state.data);
      console.log("loading results...");

      return (
        <div>
          {console.log(data)}
          {console.log("Loading full file and data")}
          {/* {console.log(!this.state.data)} */}
          <TopBarSelectorNoClick />
          <Loading />;
        </div>
      );
    } else {
      return (
        <div>
          <Helmet>
            <title>
              XHighlight{" "}
              {this.state.data.menu.files[this.state.data.activeFile]} Analyisis
            </title>
          </Helmet>
          {console.log(data)}
          {console.log("Loading full file and data")}
          {/* {console.log(!this.state.data)} */}
          <div className="d-none d-lg-block">
            <GreyContainer>
              <h2
                style={{
                  fontWeight: "bolder",
                  marginTop: "9px",
                  padding: "15px 0",
                }}
              >
                Your Highlights
                {/* {context.unique_name}*/}
                {/* { console.log(context.unique_name) } */}
                <span
                  style={{
                    fontSize: "30px",
                    float: "right",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    flexDirection: "row",
                  }}
                >
                  {" "}
                  {this.state.data.menu.files[this.state.data.activeFile]}
                </span>
              </h2>
            </GreyContainer>
          </div>
          <div className="d-lg-none">
            <GreyContainer>
              <h2
                style={{
                  fontWeight: "bolder",
                  marginTop: "9px",
                  padding: "15px 0 0px 0",
                }}
              >
                Your Highlights:
              </h2>
              <h5
                style={{
                  marginTop: "4px",
                  padding: "15px 0 15px 0",
                }}
              >
                {this.state.data.menu.files[this.state.data.activeFile]}
              </h5>
            </GreyContainer>
          </div>
          {/* {console.log(this.state.data.menu.files)} */}
          {/* {console.log("this.state.data.menu.files")} */}

          {this.state.data.menu.files.length > 1 ? (
            <Container>
              <TopBarSelector
                // highlights={this.state.highlights}
                activeFile={this.state.data.activeFile}
                menu={this.state.data.menu.files}
                uniqueFiles={this.state.data.uniqueFiles.files}
                newPage={this.selectResultsData}
              />
            </Container>
          ) : null}

          <Container>
            {this.state.data.thisFilesData.upper.length === 0 ? (
              <div>
                <div style={{ textAlign: "center" }}>
                  <h1>
                    Uh Oh, No highlights were found in this file.
                    {/* {console.log("Short")} */}
                  </h1>
                  <h3>Try a different file.</h3>
                </div>
                <h5>Why did my file fail?</h5>
                <p>
                  Make sure the file contains audio and video and that the audio
                  levels vary.
                </p>
              </div>
            ) : (
              <div>
                {/* <h1>{console.log("Long")}</h1> */}
                {/* {window.props.map(home => <div>{"test"}</div>)} */}
                <div className="row" style={{ paddingTop: "10px" }}>
                  {console.log(
                    "Results " +
                    this.state.data.uniqueFiles.files[
                    this.state.data.activeFile
                    ]
                  )}
                  <Result
                    upper={this.state.data.thisFilesData.upper}
                    toggleStar={this.toggleStar}
                    file_name={
                      this.state.data.uniqueFiles.files[
                      this.state.data.activeFile
                      ]
                    }
                  />
                </div>
              </div>
            )}
          </Container>

          <GreyContainer>
            <h2
              style={{
                fontWeight: "bolder",
                marginTop: "9px",
                padding: "15px 0",
                margin: "30px 0",
              }}
            >
              Full Video
            </h2>
          </GreyContainer>
          <FullVideo
            file_name={
              this.state.data.uniqueFiles.files[this.state.data.activeFile]
            }
          />
        </div>
      );
    }
  }
}

export default UploadResults;
