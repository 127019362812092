import React, { Component } from 'react';
import Upload from '../../Assets/Custom Assets/Uplaod Icon 3.png';
import MachineLearning from '../../Assets/Custom Assets/ML Icon.png';
import Results from '../../Assets/Custom Assets/Results 2.png';
import Review from '../../Assets/Custom Assets/Review.png';

class HowWorks extends Component {
  render() {
    return (
      <div>
        <h1
          style={{ margin: '50px 0 30px 0', paddingTop: '30px' }}
          className='headings'
        >
          How It Works
        </h1>
        <div className='row'>
          <div className='col-lg-3 centered'>
            <h2 className='subheadings'>Upload</h2>
            <img
              src={Upload}
              className='img-fluid'
              alt='First step, uploading your video'
              width='275px'
            />
            <h5 style={{ marginLeft: '25px', margin: '20px 0 30px 0' }}>
              Upload your Video to our platform as so we can analyze it.
            </h5>
          </div>
          <hr className='clearfix w-100 d-lg-none pb-3' />
          <div className='col-lg-3 centered'>
            <h2 className='subheadings'>Machine Learning</h2>
            <img
              src={MachineLearning}
              className='img-fluid'
              alt='We use machine learning and custom mathmatical models to find your highlights. No humans are involved at all.'
              width='275px'
            />
            <h5 style={{ margin: '20px 0 30px 0' }}>
              Our Machine Learning and Mathmatical Models will process your data
              and find all the highlights contained in the video
            </h5>
          </div>
          <hr className='clearfix w-100 d-lg-none pb-3' />
          <div className='col-lg-3 centered'>
            <h2 className='subheadings'>Results</h2>
            <img
              src={Results}
              className='img-fluid'
              alt='Review your files in our custom dashboard'
              width='275px'
            />
            <h5 style={{ margin: '20px 0 30px 0' }}>
              {' '}
              After just a few seconds, your results will become available for
              you to view in our custom dashboard
            </h5>
          </div>
          <hr className='clearfix w-100 d-lg-none pb-3' />
          <div className='col-lg-3 centered'>
            <h2 className='subheadings'>Generate Video</h2>
            <img
              src={Review}
              className='img-fluid'
              alt='Review your files in our custom dashboard'
              width='275px'
            />
            <h5 style={{ margin: '20px 0 30px 0' }}>
              Once you have chosen your clips, the full video will be available,
              or, you can download the clips and assemble them on your own.
            </h5>
          </div>
        </div>
      </div>
    );
  }
}

export default HowWorks;
