import React, { Component } from "react";
import Highlight from "./DemoHighlight";

class Result extends Component {
  render() {
    return this.props.upper.map((highlight) => (
      <Highlight
        highlight={highlight} 
        key={highlight.id}
        // highlight={highlight}
        // file_name={this.props.file_name}
        toggleStar={this.props.toggleStar}
      />
    ));
  }
}

export default Result;
