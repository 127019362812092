import React, { Component } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import {
  Player,
  ControlBar,
  ReplayControl,
  ForwardControl,
  CurrentTimeDisplay,
  TimeDivider,
  PlaybackRateMenuButton,
  // VolumeMenuButton,
} from "video-react";

class Highlight extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = { isToggleOn: false };
    this.play = this.resetStartAtTime.bind(this);
    this.toggleVisual = this.toggleVisual.bind(this);
    this.starredHighlights = [];

    // this.state.endTimeSeconds = this.props.end.endTimeSeconds;
    this.state.startSecondsOnly = (
      "0" + this.props.start.startTimeSeconds
    ).slice(-2);
    this.state.startMinsOnly = (
      "00" + Math.floor(this.props.start.startTimeSeconds / 60)
    ).slice(-2);

    this.state.endTimeTotal =
      this.state.endSecondsOnly + this.state.endMinsOnly;
      this.state.endSecondsOnly = (
        "0" + this.props.end.endTimeSeconds
      ).slice(-2);
      this.state.endMinsOnly = (
        "00" + Math.floor(this.props.end.endTimeSeconds / 60)
      ).slice(-2);
      this.state.endTimeTotal =
        this.state.endSecondsOnly + this.state.endMinsOnly;
    // this.changeMin = this.changeMin.bind(this);
  }

  handleStateChange(state) {
    this.setState({
      player: state,
    });
  }

  resetStartAtTime(startTimeSecondsForRestart) {
    console.log(this.state.startTimeSeconds);
    console.log(startTimeSecondsForRestart);
    this.player.seek(startTimeSecondsForRestart);
    this.player.play();
  }

  toggleVisual(id) {
    this.setState((state) => ({
      isToggleOn: !state.isToggleOn,
    }));
    this.starredHighlights.push(id);
    this.props.toggleStar(id);
    // this.getStyle()
  }

  changeTime(timeType, direction) {
    timeType += direction;
    // this.setState( timeType: )
  }

  changeMin() {
    // this.setState{(endTimeSeconds: 60)}
    this.setState({
      startTimeSeconds: this.state.startTimeSeconds,
    });
    console.log("change");
    // this.setState(( timeType: 5 )}
  }

  getStyle = () => {
    return {
      backgroundColor: "#cccccc",
      borderRadius: "20px",
      padding: "30px",
      margin: "50px 0 ",
    };
  };

  render() {
    // let { this.state.startTimeSeconds, startTimeMin } = this.props.start;
    // let { endTimeSeconds, endTimeMin } = this.props.end;
    // this.state.startTimeSeconds = parseInt(this.state.startTimeSeconds) - 3;

    let src_base_url = "http://localhost:3001/uploads/";
    let src_sub_url = this.props.file_name;

    src_sub_url = src_sub_url.replace(" ", "%20");

    console.log("full " + src_base_url + src_sub_url);

    return (
      <div>
        {console.log(this.state.secondsOnly)}
        <div
          className="col-md-1-12"
          style={{ textAlign: "center", width: "80%", margin: "auto" }}
        >
          <Player
            ref={(player) => {
              this.player = player;
            }}
            startTime={this.state.startTimeSeconds}
            src={src_base_url + src_sub_url}
          >
            <ControlBar>
              <ReplayControl seconds={10} order={1.1} />
              <ForwardControl seconds={30} order={1.2} />
              <CurrentTimeDisplay order={4.1} />
              <TimeDivider order={4.2} />
              <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
            </ControlBar>
          </Player>
        </div>
        {/* <div style={{}}> */}
        <div style={{ margin: "0 115px" }}>
          <div
            style={{
              float: "right",
              position: "relative",
              marginRight: "90px",
              marginTop: "35px",
            }}
          >
            <button
              style={{
                backgroundColor: "transparent",
                border: "none",
                outline: "none",
                // float: "right",
              }}
              onClick={() => this.resetStartAtTime(this.state.startTimeTotal)}
              // onclick="star(id)"
            >
              <p style={{ padding: "0px 0px 0 0px", fontSize: "50px" }}>↺</p>
              <h4 style={{ margin: "-27px 0px 0 0px" }}>Restart Clip</h4>
            </button>
          </div>
          <form
            onKeyDown={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          >
            <div style={this.getStyle()}>
              <h4
                style={{ paddingTop: "15px", fontSize: "30px" }}
                // onClick={() => this.resetStartAtTime(this.state.startTimeSeconds)}
              >
                Clip Start Time:{"  "}
                <input
                  type="number"
                  name="startTimeMins"
                  min="0"
                  defaultValue={this.state.startMinsOnly}
                  min="0"
                  max="60"
                  maxlength="2"
                  style={{
                    width: "55px",
                    textAlign: "right",
                    // height: "23px",
                    fontWeight: "bold",
                    margin: 0,
                    padding: 0,
                    border: "none",
                  }}
                  // onChange={this.changeMin}
                  onChange={async (e) =>{
                    // this.setState({
                    //   startTimeSeconds:
                    //     parseInt(e.target.value * 60) + parseInt(this.state.startSecondsOnly),
                    // });
                    await this.setState({
                      startMinsOnly: parseInt(e.target.value) * 60,
                    });
                    this.setState({
                      startTimeTotal: parseInt(this.state.startMinsOnly) + parseInt(this.state.startSecondsOnly)
                    });
                    console.log(this.state.startTimeTotal);
                  }}
                />{" "}
                :{" "}
                <input
                  type="number"
                  name="startTimeSecs"
                  min="0"
                  max="59"
                  maxlength="2"
                  // defaultValue={("0" + this.state.startTimeSeconds).slice(-2)}
                  defaultValue={this.state.startSecondsOnly}
                  style={{
                    width: "55px",
                    // height: "23px",
                    fontWeight: "bold",
                    margin: 0,
                    padding: 0,
                    border: "none",
                  }}
                  onChange={async (e) =>{
                    // this.setState({
                    //   startTimeSeconds:
                    //     parseInt(e.target.value * 60) + parseInt(this.state.startSecondsOnly),
                    // });
                    await this.setState({
                      startSecondsOnly: parseInt(e.target.value),
                    });
                    this.setState({
                      startTimeTotal: parseInt(this.state.startMinsOnly) + parseInt(this.state.startSecondsOnly)
                    });
                    console.log(this.state.startTimeTotal);
                  }}
                />
              </h4>
              <h4
                style={{ paddingTop: "15px", fontSize: "30px" }}
                // onClick={() => this.resetStartAtTime(this.state.startTimeSeconds)}
              >
                Clip End Time:{"  "}
                <input
                  type="number"
                  name="endTimeTime"
                  max="60"
                  min="0"
                  defaultValue={this.state.endMinsOnly}
                  style={{
                    width: "55px",
                    textAlign: "right",
                    fontWeight: "bold",
                    margin: 0,
                    padding: 0,
                    marginLeft: "10px",

                    border: "none",
                  }}
                  onChange={async (e) =>{
                    await this.setState({
                      endMinsOnly: parseInt(e.target.value) * 60,
                    });
                    this.setState({
                      endTimeTotal: parseInt(this.state.endMinsOnly) + parseInt(this.state.endSecondsOnly)
                    });
                    console.log(this.state.endTimeTotal);
                  }}
                />{" "}
                :{" "}
                <input
                  type="number"
                  name="endTimeSecs"
                  max="59"
                  min="0"
                  defaultValue={this.state.endSecondsOnly}
                  style={{
                    width: "55px",
                    // height: "23px",
                    fontWeight: "bold",
                    margin: 0,
                    padding: 0,
                    border: "none",
                  }}
                  onChange={async (e) =>{
                    // this.setState({
                    //   startTimeSeconds:
                    //     parseInt(e.target.value * 60) + parseInt(this.state.startSecondsOnly),
                    // });
                    await this.setState({
                      endSecondsOnly: parseInt(e.target.value),
                    });
                    this.setState({
                      endTimeTotal: parseInt(this.state.endMinsOnly) + parseInt(this.state.endSecondsOnly)
                    });
                    console.log(this.state.endTimeTotal);
                  }}
                />
              </h4>
              {/* <br />
            <textarea
              placeholder="Notes"
              style={{ width: "70%", margin: "0px" }}
              defaultValue={""}
            /> */}
              <br />
              <h4>
                Add Custom Splash Screen to the end (optional):
                <input type="file" name="splash" />
              </h4>
              <h4>
                Add custom watermark (optional):{"   "}
                <input type="file" name="watermark" />
              </h4>
              <br />
              <div style={{ textAlign: "center" }}>
                <button
                  type="Submit"
                  style={{
                    border: "none",
                    color: "white",
                    padding: "20px",
                    textAlign: "center",
                    display: "inline-block",
                    fontSize: "45px",
                    // marginTop: "20px",
                    fontWeight: "bold",
                    borderRadius: "30px",
                  }}
                  className="btn red"
                >
                  <span>Generate & Download</span>
                </button>
              </div>
            </div>
          </form>
        </div>
        {/* <button>Generate & Download Clip</button> */}
      </div>
    );
  }
}

//PropTypes
Highlight.propType = {
  todo: PropTypes.object.isRequired,
};

export default Highlight;
