import React from 'react';
import GreyContainer from '../Univeral Components/GreyContainer';
import Container from '../Univeral Components/Container';
import TagLine from './Components/TagLine';
import GetStartedBottom from './Custom Page Components/GetStartedBottom';
import Upload from './../Assets/Custom Assets/Upload-Video-large.svg';
import MachineLearning from './../Assets/Custom Assets/ML Icon.png';
import Results from './../Assets/Custom Assets/Results 2.png';
import AutoVideoCreation from './../Assets/Custom Assets/Video Icon Outline.png';

class Home extends React.Component {
  render() {
    return (
      <div>
        <TagLine />
        <div>
          <GreyContainer>
            <h3 style={{ padding: '50px 0' }} className='headings'>
              How does XHighlight Work?
            </h3>
          </GreyContainer>
          <div className='container'>
            {/* <h1 class="mt-4 text-center">XHighlight: Fastest and Easiest way to make your Highlight Videos</h1>
    <hr> */}
            <div className='row justify-content-center align-self-center'>
              <div className='row'>
                <div className='col-lg-3'>
                  <Container>
                    <div
                      className='d-flex h-100'
                      style={{ padding: '30px 0px' }}
                    >
                      <div className='justify-content-center align-self-center'>
                        <img
                          src={Upload}
                          className='img-fluid'
                          alt=''
                          max-width='262px'
                        />
                      </div>
                    </div>
                  </Container>
                </div>
                {/* ! TODO: Fill in col 4 with writing */}
                <div className='col-lg-9'>
                  <div style={{ paddingLeft: '40px' }}>
                    <h1 className='subheadings'>Upload your video</h1>
                    <p className='explain'>
                      To start the process, upload your video for our analysis.
                      You can upload one, or multiple videos at the same time.
                    </p>
                  </div>
                  {/* ! TODO: Fill in col 8 with image */}
                </div>
              </div>
            </div>
          </div>
          <GreyContainer
            className='light-grey-style'
            style={{ padding: '50px 0px 50px 0px' }}
          >
            <div className='row'>
              <div className='col-lg-8'>
                <h1 className='subheadings'>Algorithms and Machine Learning</h1>
                <p className='explain'>
                  Once your file is uploaded, our proprietary algorithms and
                  machine learning modules will instently begin processing your
                  files. These algorthms, require no human interaction, meaning
                  your file is not looked at by any humans while it is being
                  processed. Your file only remains on our servers for a short
                  while after before it is automatically deleted from our
                  servers.
                </p>
              </div>
              {/* ! TODO: Fill in col 8 with image */}
              <div className='col-lg-4'>
                <div
                  className='container d-flex h-100'
                  style={{ padding: '30px' }}
                >
                  <div className='justify-content-center align-self-center'>
                    <img
                      src={MachineLearning}
                      className='img-fluid'
                      alt=''
                      max-width='262px'
                    />
                  </div>
                </div>
              </div>
            </div>
          </GreyContainer>
          {/* Page Content */}
          <Container>
            <div className='row justify-content-center align-self-center'>
              <div className='row'>
                <div className='col-lg-3'>
                  <div className='d-flex h-100' style={{ padding: '30px 0px' }}>
                    <div className='justify-content-center align-self-center'>
                      <img
                        src={Results}
                        className='img-fluid'
                        alt=''
                        max-width='262px'
                      />
                    </div>
                  </div>
                </div>{' '}
                {/* ! TODO: Fill in col 4 with writing */}
                <div className='col-lg-9'>
                  <div style={{ marginLeft: '40px' }}>
                    <h1 className='subheadings'>See Your Results</h1>
                    <p className='explain'>
                      After just a few seconds, our algorithms will allow you to
                      see where your highlights are. You can mark them as
                      favorites and dowload a list of the location of your
                      favorites for reference and for our algorithm to spotlight
                      them in the final video.
                    </p>
                  </div>
                </div>
                {/* ! TODO: Fill in col 8 with image */}
                {/* <img src="https://storage.googleapis.com/gd-wagtail-prod-assets/images/evolving_google_identity_2x.max-4000x2000.jpegquality-90.jpg"> */}
              </div>
            </div>
          </Container>
          <div className='light-grey-style' style={{ padding: '30px 0px' }}>
            <Container>
              <div className='row'>
                <div className='col-lg-8'>
                  <h1 className='subheadings'>
                    Create your Video (Coming Soon)
                  </h1>
                  <p className='explain'>
                    Once you have marked your favorite highlights, simply click
                    generate and our algorithms generate a video within your
                    specified length.
                  </p>
                </div>{' '}
                {/* ! TODO: Fill in col 8 with image */}
                <div className='col-lg-4'>
                  <div className='container d-flex h-100'>
                    <div className='justify-content-center align-self-center'>
                      <img
                        src={AutoVideoCreation}
                        className='img-fluid'
                        alt='Create Your Video With XHighlight (Coming Soon)'
                        max-width='262px'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </div>
        <div style={{ paddingTop: '30px' }}>
          <GetStartedBottom></GetStartedBottom>
        </div>
      </div>
    );
  }
}

export default Home;
