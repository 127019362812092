import React from "react";
import Result from "./Outcomes Page/Result";
import FullVideo from "./Outcomes Page/FullVideo";
import Container from "../Univeral Components/Container";
import RedContainer from "../Univeral Components/RedContainer";
import GreyContainer from "../Univeral Components/GreyContainer";
import "~./../video-react/dist/video-react.css"; // import css
import { Helmet } from "react-helmet";
import ResultsExtraInfo from "./Custom Page Components/ResultsExtraInfo";
import FileResults from "./Programming Results/Wellesley Senior Night 2021.json"

class UploadResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // data: null,
      data: FileResults,
      cancel: false,
      counter: 0,
      complete: false,
      activeNumber: 0,
      newUrl: null,
    };
  }

  render() {
    const { data } = this.state;
    return (
      <div>
        <Helmet>
          <title>
            XHighlight |{" "}
            {this.state.data.menu.files[this.state.data.activeFile]} Analyisis
          </title>
        </Helmet>
        {console.log(data)}
        {console.log("Loading full file and data")}
        {console.log(!this.state.data)}
        <div className="d-none d-lg-block">
          <GreyContainer>
            <h2
              style={{
                fontWeight: "bolder",
                marginTop: "9px",
                padding: "15px 0",
              }}
            >
              Your Highlights
              {/* {context.unique_name}*/}
              {/* { console.log(context.unique_name) } */}
              <span
                style={{
                  fontSize: "30px",
                  float: "right",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  flexDirection: "row",
                }}
              >
                {" "}
                {this.state.data.menu.files[this.state.data.activeFile]}
              </span>
            </h2>
          </GreyContainer>
        </div>
        <div className="d-lg-none">
          <GreyContainer>
            <h2
              style={{
                fontWeight: "bolder",
                marginTop: "9px",
                padding: "15px 0 0px 0",
              }}
            >
              Your Highlights:
            </h2>
            <h5
              style={{
                marginTop: "4px",
                padding: "15px 0 15px 0",
              }}
            >
              {this.state.data.menu.files[this.state.data.activeFile]}
            </h5>
          </GreyContainer>
        </div>

        <Container>
          <div>
            <div className="row" style={{ paddingTop: "10px" }}>
              <Result
                upper={this.state.data.thisFilesData.upper}
                toggleStar={this.toggleStar}
                file_name={
                  this.state.data.uniqueFiles.files[this.state.data.activeFile]
                }
              />
            </div>
          </div>
        </Container>

        <RedContainer>
          <ResultsExtraInfo data={this.state.data} />
        </RedContainer>

        <GreyContainer>
          <h2
            style={{
              fontWeight: "bolder",
              marginTop: "9px",
              padding: "15px 0",
              margin: "30px 0",
            }}
          >
            Full Video
          </h2>
        </GreyContainer>
        <FullVideo
          file_name={
            this.state.data.uniqueFiles.files[this.state.data.activeFile]
          }
        />
      </div>
    );
  }
}

export default UploadResults;
