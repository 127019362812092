import React, { Component } from "react";
import Container from "./../../Univeral Components/Container";

class GetStartedBottom extends Component {
  render() {
    return (
      <Container>
        <div style={{ textAlign: "center" }}>
          <h4 style={{ margin: "0px", fontWeight: "bold", fontSize: "3em" }}>
            Get started now by uploading your first video!
          </h4>
          <a href="/upload">
            <button
              style={{
                border: "none",
                color: "white",
                padding: "20px",
                textAlign: "center",
                display: "inline-block",
                fontSize: "50px",
                marginTop: "20px",
                fontWeight: "bold",
                borderRadius: "30px",
              }}
              className="btn red"
            >
              <span>Get Started Now</span>
            </button>
          </a>
        </div>
      </Container>
    );
  }
}

export default GetStartedBottom;
