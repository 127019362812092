import React, { Component } from "react";
import "./ResultsStyle.css";
import "./../Outcomes.css";
import Container from "./../../Univeral Components/Container";
import GreyContainer from "./../../Univeral Components/GreyContainer";
import PropTypes from "prop-types";

class TopBarSelector extends Component {
  render() {
    return (
      <div>
        <div className="d-none d-lg-block">
          <GreyContainer>
            <h2
              style={{
                fontWeight: "bolder",
                marginTop: "9px",
                padding: "15px 0",
              }}
            >
              Your Highlights
              <span
                style={{
                  fontSize: "30px",
                  float: "right",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  flexDirection: "row",
                }}
              >
                {" "}
              </span>
            </h2>
          </GreyContainer>
        </div>
        <div className="d-lg-none">
          <GreyContainer>
            <h2
              style={{
                fontWeight: "bolder",
                marginTop: "9px",
                padding: "15px 0 0px 0",
              }}
            >
              Your Highlights:
            </h2>
            <h5
              style={{
                marginTop: "4px",
                padding: "15px 0 15px 0",
              }}
            >
            </h5>
          </GreyContainer>
        </div>
        <Container>
          <div style={{ marginTop: "50px" }}>
            {console.log("No Click")}
            <div
              className="bar"
              style={{
                fontWeight: "bold",
                marginLeft: "auto",
                marginRight: "auto",
                paddingTop: "17px",
                fontWeight: "bold",
              }}
            >
              <div className="align-container">
                <div className="align-left">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={48}
                    height={48}
                    viewBox="0 0 48 48"
                  >
                    <path d="M30.83 32.67l-9.17-9.17 9.17-9.17L28 11.5l-12 12 12 12z" />
                    <path d="M0-.5h48v48H0z" fill="none" />
                  </svg>
                </div>
                <div className="align-right">
                  <div
                    style={{
                      textAlign: "right",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "flex-end",
                      }}
                    >
                      <div
                        style={{
                          overflow: "hidden",
                          display: "block",
                          // padding: "5px",
                          paddingTop: "22.5px",
                          // minWidth: 0,
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          flexDirection: "row",
                        }}
                      >
                      </div>
                      <div style={{ flexShrink: "0" }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={48}
                          height={48}
                          viewBox="0 0 48 48"
                        >
                          <path d="M17.17 32.92l9.17-9.17-9.17-9.17L20 11.75l12 12-12 12z" />
                          <path d="M0-.25h48v48H0z" fill="none" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ clear: "both" }} />
            </div>
          </div>
        </Container>
      </div>
    );
  }
}
//PropTypes
TopBarSelector.propType = {
  todo: PropTypes.object.isRequired,
};

export default TopBarSelector;
