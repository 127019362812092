import React from "react";
import Container from "../Univeral Components/Container";
import TagLine from "./Components/TagLine";
import AccordianElement from "./Custom Page Components/AccordionElements";
import AccordianElementChild from "./Custom Page Components/AccordionElementsChild";
import GetStartedBottom from "./Custom Page Components/GetStartedBottom";
import GreyContainer from "../Univeral Components/GreyContainer";
import FAQCombined from "./Custom Page Components/FAQsSpecific/FAQCombined";
import { Helmet } from 'react-helmet';



class Help extends React.Component {
  render() {
    return (
      <div>
         <Helmet>
          <title>XHighlight Frequently Asked Questions</title>
        </Helmet>
        <TagLine />
        <Container>
            <FAQCombined/>
        </Container>
        <GreyContainer>
          <div style={{ padding: "30px 0px", marginTop: "50px" }}>
              <h5>
            Have a question we didn't cover? Email us:{" "}
            <a href="mailto: contact@xhighlight.com" target="_top">
              contact@xhighlight.com
            </a>.
            </h5>
          </div>
        </GreyContainer>
        <div style={{ padding: "30px 0" }}>
          {/* <hr style={{ border: "1px dashed black" }} /> */}
        </div>
        <GetStartedBottom />
      </div>
    );
  }
}

export default Help;
