import React, { Component } from "react";
import "./ResultsStyle.css";
import "./../Outcomes.css";
import PropTypes from "prop-types";

class TopBarSelector extends Component {
  constructor(props) {
    super(props);
    this.getMenu = this.getMenu.bind(this);
  }

  componentDidMount() {
    window.addEventListener("load", this.getMenu);
  }

  getMenu() {
    let before = 0;
    let after = 0;
    let active = this.props.activeFile;
    let menu = this.props.menu;
    // let menu = ""
    console.log("menu");

    console.log(menu);
    if (active === 0) {
      before = menu.length;
    } else before = active;
    if (active === menu.length - 1) {
      after = 0;
    } else after = active + 1;
    return [this.props.menu[before - 1], this.props.menu[after]];
  }

  render() {
    // const { activeFile } = this.props.activeFile;
    return (
      <div style={{ marginTop: "50px" }}>
        <div
          className="bar"
          style={{
            fontWeight: "bold",
            marginLeft: "auto",
            marginRight: "auto",
            paddingTop: "17px",
            fontWeight: "bold",
          }}
        >
          {/* <div style={{ width: "100%", display:"inline-block"}}> */}
          {/* <div class='container'><div class="align-left">left</div><div class="align-right">right</div></div> */}
          {/* <div className="alignleft3" style={{ paddingRight: "20px" }}> */}
          <div className="align-container">
            <div className="align-left">
              <button
                style={{
                  fontWeight: "bold",
                  border: "none",
                  background: "none",
                  cursor: "pointer",
                  margin: "0",
                  padding: "0",
                }}
                onClick={() => {
                  this.props.newPage(0);
                }}
              >
                {/* onClick={this.props.newPage(0)} style={{ fontWeight: "bold"}}>  */}
                {/* 0 is previous, 1 is next */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={48}
                  height={48}
                  viewBox="0 0 48 48"
                >
                  <path d="M30.83 32.67l-9.17-9.17 9.17-9.17L28 11.5l-12 12 12 12z" />
                  <path d="M0-.5h48v48H0z" fill="none" />
                </svg>
                {this.getMenu()[0]}
              </button>
            </div>

            {/* <div className="alignright3" style={{ paddingLeft: "20px" }}> */}
            <div className="align-right">
              <div
                style={{
                  textAlign: "right",
                }}
              >
                <button
                  onClick={() => {
                    this.props.newPage(1);
                  }}
                  style={{
                    fontWeight: "bold",
                    border: "none",
                    background: "none",
                    cursor: "pointer",
                    margin: "0",
                    padding: "0",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div
                      style={{
                        overflow: "hidden",
                        display: "block",
                        // padding: "5px",
                        paddingTop: "22.5px",
                        // minWidth: 0,
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        flexDirection: "row",
                      }}
                    >
                      {this.getMenu()[1]}
                    </div>
                    <div style={{ flexShrink: "0" }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={48}
                        height={48}
                        viewBox="0 0 48 48"
                      >
                        <path d="M17.17 32.92l9.17-9.17-9.17-9.17L20 11.75l12 12-12 12z" />
                        <path d="M0-.25h48v48H0z" fill="none" />
                      </svg>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div style={{ clear: "both" }} />
        </div>
      </div>
    );
  }
}
//PropTypes
TopBarSelector.propType = {
  todo: PropTypes.object.isRequired,
};

export default TopBarSelector;
