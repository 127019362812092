import React, { Component } from 'react';
import './ResultsStyle.css';
import '../../Outcomes.css';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import ReactPlayer from 'react-player';

import {
  Player,
  ControlBar,
  ReplayControl,
  ForwardControl,
  CurrentTimeDisplay,
  TimeDivider,
  PlaybackRateMenuButton,
  // VolumeMenuButton,
} from 'video-react';

class Highlight extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = { isToggleOn: this.props.highlight.Starred };
    this.play = this.resetStartAtTime.bind(this);
    this.toggleVisual = this.toggleVisual.bind(this);
    // this.toggleStar = this.toggleStar.bind(this);
    this.starredHighlights = [];
  }

  handleStateChange(state) {
    // copy player state to this component's state
    this.setState({
      player: state,
    });
  }

  resetStartAtTime(startTimeSecondsForRestart) {
    this.player.seek(startTimeSecondsForRestart);
    this.player.play();
  }

  toggleVisual(id) {
    this.setState((state) => ({
      isToggleOn: !state.isToggleOn,
    }));
    // this.starredHighlights.push(id);
    // this.props.toggleStar(id);
  }

  getStyle = () => {
    return {
      // backgroundColor: this.props.highlight.Starred
      //   ? "rgb(255, 2, 0)"
      //   : "#cccccc",

      // Both above and below work just a preference
      backgroundColor: this.state.isToggleOn ? 'rgb(255, 2, 0)' : '#cccccc',
      // ? is true, : is false
      borderRadius: '20px',
      padding: '30px 0',
      margin: '30px',
    };
  };

  render() {
    const { id, startTimeMin } = this.props.highlight;

    let { startTimeSeconds } = this.props.highlight;
    startTimeSeconds = parseInt(startTimeSeconds) - 7;
    return (
      <div className='col-lg-6'>
        {console.log(this.props.highlight.XScore)}
        <div style={this.getStyle()}>
          <div
            className='col-md-1-12'
            style={{ textAlign: 'center', width: '80%', margin: 'auto' }}
          >
            <Player
              ref={(player) => {
                this.player = player;
              }}
              startTime={startTimeSeconds}
              src={process.env.REACT_APP_DEMO_VIDEO_URL}
            >
              <ControlBar>
                <ReplayControl seconds={10} order={1.1} />
                <ForwardControl seconds={30} order={1.2} />
                <CurrentTimeDisplay order={4.1} />
                <TimeDivider order={4.2} />
                <PlaybackRateMenuButton
                  rates={[5, 2, 1, 0.5, 0.1]}
                  order={7.1}
                />
              </ControlBar>
            </Player>
            {/* <ReactPlayer
              url={process.env.REACT_APP_DEMO_VIDEO_URL}
              // ref={videoPlayer}
              // startTime={startTimeSeconds}
              startTime={90}
              width='100%'
              height='auto'
              controls='true'
            /> */}
          </div>
          <div className='video-annotate'>
            <div style={{ float: 'right', position: 'relative' }}>
              <button
                style={{
                  backgroundColor: 'transparent',
                  border: 'none',
                  outline: 'none',
                  // float: "right",
                }}
                onClick={() => this.resetStartAtTime(startTimeSeconds)}
                // onclick="star(id)"
              >
                <p style={{ padding: '0px 0px 0 0px', fontSize: '40px' }}>↺</p>
              </button>
              <button
                style={{
                  backgroundColor: 'transparent',
                  border: 'none',
                  outline: 'none',
                  // float: "right",
                }}
                onClick={() => this.toggleVisual(id)}
                // onclick="star(id)"
              >
                <p
                  style={{ padding: '0 40px 0 0px', fontSize: '40px' }}
                  id='star'
                >
                  {this.state.isToggleOn ? '★' : '☆'}
                </p>
              </button>
            </div>

            <h4
              style={{ paddingTop: '15px', fontSize: '30px' }}
              onClick={() => this.resetStartAtTime(startTimeSeconds)}
            >
              Time: {startTimeMin}
            </h4>
            <br />
            <textarea
              placeholder='Notes'
              style={{ width: '70%', margin: '0px' }}
              defaultValue={''}
            />
          </div>
        </div>
        {/* </div> */}
      </div>
    );
  }
}

//PropTypes
Highlight.propType = {
  todo: PropTypes.object.isRequired,
};

export default Highlight;
