import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import Container from "../../Univeral Components/Container";
import styled from "styled-components";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import TypeForm from "./Typeform"


function DropElement() {
    function MyVerticallyCenteredModal(props) {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        XHighlight is still in beta. Join Our Waiting List to learn when we launch to the public.
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ height: "500px" }}>
                    <h4>Enter your email to get updates on our launch.</h4>
                    {/* <p>
            Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
            dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
            consectetur ac, vestibulum at eros.
          </p> */}
                    <p style={{ height: "200px" }}>
                        <TypeForm />
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    const [modalShow, setModalShow] = React.useState(false);

    return (
        <div style={{ textAlign: 'center' }}>
            <button
                className="col-11 btn-white"
                type="button"
                style={{
                    margin: "20px 0 20px 0",
                    border: "none",
                    color: "black",
                    padding: "20px",
                    textAlign: "center",
                    display: "inline-block",
                    borderRadius: "30px",
                }}
                className="btn-white col-11"
                onClick={() => setModalShow(true)}
            >
                <span
                    style={{
                        fontSize: "30px",
                        margin: "5px 0 5px 0",
                        fontWeight: "bold",
                    }}
                >
                    Notify Me
                </span>
            </button>
            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
            <div className="col-12">
                <a href="/demo">
                    <button
                        style={{
                            margin: "20px 0 20px 0",
                            border: "none",
                            color: "white",
                            padding: "20px",
                            textAlign: "center",
                            display: "inline-block",
                            borderRadius: "30px",
                        }}
                        className="btn red col-5"
                    >
                        <span
                            style={{
                                fontSize: "30px",
                                margin: "5px 0 5px 0",
                                fontWeight: "bold",
                            }}
                        >
                            View Demo
                        </span>
                    </button>
                </a>
            </div>
        </div>
    );


}

export default DropElement;
