import React from 'react';
// import GreyContainer from "../Univeral Components/GreyContainer";
import Container from '../Univeral Components/Container';
import TagLine from './Components/TagLine';
// import GetStartedBottom from "./Custom Page Components/GetStartedBottom";
import GetStartedBottom from './Custom Page Components/GetStartedBottom';
// import FormUploader from './Custom Page Components/FormUploader';

class Help extends React.Component {
  render() {
    return (
      <div style={{ marginTop: '20px' }}>
        <Container>
          <h1 style={{ textAlign: 'center' }}>Coming Soon</h1>
        </Container>
        {/* <TagLine/> */}
        {/* <Container>
          <div className='row'>
            <h2 className='mt-4 headings'>
              Need Help? Have a Feature Request? Want to Contact Us?
            </h2>
            <h5 style={{ marginTop: '20px' }}>
              We answer the most frequent questions in on our{' '}
              <a href='/faq'>FAQs</a> Page. For questions about your data, you
              can visit our <a href='data'>Data page</a>, or our{' '}
              <a href='data-and-privacy'>Data and Privacy page.</a> If you have
              a question we didn't answer, feel free to email us using the form
              below or at{' '}
              <a href='mailto: contact@xhighlight.com' target='_top'>
                contact@xhighlight.com
              </a>
              .
            </h5>
            <br />
          </div>
        </Container>
        <div className='red-style-white-text' style={{ paddingBottom: '20px' }}>
          <Container>
            <form onSubmit={this.handleSubmit}>
              <div className='row'>
                <div className='col-sm-12'></div>
              </div>
              <FormUploader />
            </form>
          </Container>
        </div>
        <div style={{ marginTop: '20px' }}>
          <GetStartedBottom />
        </div> */}
      </div>
    );
  }
}

export default Help;
