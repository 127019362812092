import React from "react";
import GreyContainer from "../Univeral Components/GreyContainer";
import Container from "../Univeral Components/Container";
import TagLine from "./Components/TagLine";
// import DropElement from "./Custom Page Components/DropElement";
import HowWorks from "./Custom Page Components/HowWorks";
// import DropElement from "./Custom Page Components/DropElement2
// import DropElement from "./Custom Page Components/DropElement7";
// import HTMLInputElement from "./Custom Page Components/HTMLInputElement";
// import DropElement from "./Custom Page Components/DropElement";
import DropElement from "./Custom Page Components/DropElement";
import { Helmet } from 'react-helmet';

class Upload extends React.Component {
  render() {
    return (
      <div>
         <Helmet>
          <title>XHighlight: Game Detection Uploader</title>
        </Helmet>
        <TagLine />
            <DropElement />
        <GreyContainer>
          <div style={{ padding: "20px 0" }}>
            {/* <a href="#tips"> */}
              <h2
                className="mt-4 headings"
                style={{ paddingBottom: "30px", color: "black" }}
              >
                Tips for uploading
              </h2>
            {/* </a> */}
            <h5>What is the best way to upload my video?</h5>
            <p>
              All you have to do is submit your video through the above form.
            </p>
            <h5>How much does it cost?</h5>
            <p>
              It's entirely free! Currently, we are not monetizing our
              technology. We are simply working to optimize how it works and how
              our customers can utalize it.
            </p>
            <h5>Can I submit multiple videos at one time?</h5>
            <p>
              YES! you can submit up to 5 videos at a time. From there, you can
              save clips you like and downlaod a text file of the location of
              the highlights. It's that easy.
            </p>
            <h5>What is the best format to upload my video in?</h5>
            <p>
              Right now, you must upload your video file as an mp4. This is the
              most common video format. We plan to support other formats in the
              future.
            </p>
            <h5>Can I upload an MOV or M4A file?</h5>
            <p>
              Not yet. In the future, we hope to enable you to upload MOV and
              M4V files but we are not ready for that yet.
            </p>
            <h5>How should I structure my video?</h5>
            <p>
              If you recorded your video by quarter, we recommend putting the
              videos together, in the future we plan to allow this to be done
              automatically. Simply stitch the videos together for the most
              accurate results.
            </p>
            <h5>General Tips?</h5>
            <p>We recommend the following tips:</p>
            <ol>
              <li>
                Combine your videos together (including Q1, Q2, Q3, Q4, OT, etc)
                into one video.
              </li>
              <li>
                Post as much of the video as possible at one time as it will
                yield the best results.
              </li>
            </ol>
          </div>
        </GreyContainer>
        <Container>
          <HowWorks />
        </Container>
      </div>
    );
  }
}

export default Upload;
